<template>
    <div v-bind:id="divName" style="text-align: center" class="resize-multiple-line-chart"></div>
</template>

<style>
    .resize-multiple-line-chart {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        width: 100%;
        height: 83vh;
        overflow: hidden;
        resize: both;
    }
</style>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
    import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
    import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
    import am4lang_de_DE from "@amcharts/amcharts4/lang/de_DE";
// import $ from "jquery";
    import moment from "moment";
    import { decode, spreadTitle } from "../js/main";
    import normalization from "../mixins/normalization";

    export default {
        chart: {}, // <-- non-reactive property
        mixins: [normalization],
        mounted() {
            console.log("am4charts-multiple-line-chart.vue mounted. this.divName=", this.divName);
            console.log("this.$options.chart=", this.$options.chart);  // <-- use non-reactive property
        },
        beforeDestroy() {
            if (typeof this.$options.chart.dispose === "function") {
                console.log("Destroying chart.");
                this.$options.chart.dispose();
            }
        },
        props: {
            divName: {
                type: String,
                default: "chartdiv",
            },
            namespace: {
                type: String,
                default: "",
            },
            parsedData: {
                type: Object,
            },
        },
        data: function () {
            return {
                //  generalForm: {},
                chartData: [],
            };
        },
        computed: {
            stockGraphType() {
                return this.$store.state[this.namespace].stockGraphType;
            },
            addRSIPanel() {
                return this.$store.state[this.namespace].addRSIPanel;
            },
            rsiPeriod() {
                return this.$store.state[this.namespace].rsiPeriod;
            },
            cciPeriod() {
                return this.$store.state[this.namespace].cciPeriod;
            },
            cciSource() {
                // console.log("this.$store.state[this.namespace].cciSource=", this.$store.state[this.namespace].cciSource);
                return this.$store.state[this.namespace].cciSource;
            },
            addMACDPanel() {
                return this.$store.state[this.namespace].addMACDPanel;
            },
            addBollinger() {
                return this.$store.state[this.namespace].addBollinger;
            },
            addVolumePanel() {
                return this.$store.state[this.namespace].addVolumePanel;
            },
            showCOTPanel() {
                return this.$store.state[this.namespace].addCOTPanel;
            },
            loading() {
                return this.$store.state[this.namespace].loading;
            },
            skipEmptyPeriods() {
                return this.$store.state[this.namespace].chartParameters.skipEmptyPeriods;
            },
            balloons() {
                return this.$store.state[this.namespace].chartParameters.balloons;
            },
            truncate() {
                return this.$store.state[this.namespace].truncate;
            },
            locale() {
                return this.$store.state.settings.locale;
            }
        },
        watch: {
            parsedData: function (parsedData) {
                console.log("watch parsedData = ", JSON.parse(JSON.stringify(parsedData)));
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
              //  if (typeof parsedData.dataset !== "undefined") {
                    this.createMultipleLineChart(generalForm, this.divName);
              //  } else {
               //     this.removeChart();
               // }
            },
            loading: function (loading) {
                // console.log("loading watch!");
                if (loading) {
                    // document.getElementById(this.namespace).innerHTML = "";
                }
            },
            stockGraphType: function () {
                console.log("watching stockGraphType");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                console.log("generalForm=", generalForm);
                if (typeof this.$options.chart.series !== "undefined") {
                    this.createMultipleLineChart(generalForm, this.divName);
                }
            },
            skipEmptyPeriods: function () {
                if (typeof this.$options.chart.series !== "undefined") {
                    let dateAxis = this.$options.chart.map.getKey("date-axis");
                    dateAxis.skipEmptyPeriods = this.$store.state[this.namespace].chart.plotParameters.skipEmptyPeriods;
                }
            },
            balloons: function (balloons) {
                if (typeof this.$options.chart.series !== "undefined") {
                    this.$options.chart.series.values.forEach((series) => {
                        // console.log("series-", series.id, " ", series.name);
                        if (typeof series !== "undefined") {
                            series.tooltipText = balloons ? "[{color}]{name}: [/][{color}]{valueY}" : "";
                        }
                    });
                }
            },
            truncate() {
                console.log("watching truncate");
                //this.truncateData();
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.createMultipleLineChart(generalForm, this.namespace);
            },
            locale: function (locale) {
                console.log("watch: locale=", locale);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.createMultipleLineChart(generalForm, this.divName);
            }
        },
        methods: {
            removeChart: function () {
                console.log("removeChart() starting.");
                console.log("divName=", this.divName);
                if (document.getElementById(this.divName) !== null) {
                    document.getElementById(this.divName).innerHTML = "";
                    document.getElementById(this.divName).style.border = "0px solid gray";
                }
            },
            createMultipleLineChart: function (generalForm, divName = "chartdiv", chartId = "multiple-line-chart") {
                console.log("createMultipleLineChart() starting. divName=", divName);
                //  this.$options.chart.dispose && this.$options.chart.dispose(); //Not sure if this works.
                if (this.$options.chart.dispose) {
                    console.log("disposing chart.");
                    this.$options.chart.dispose();
                }

                let parsedData = this.parsedData;
                console.log("parsedData=", JSON.parse(JSON.stringify(parsedData)));

                if(typeof parsedData.values === 'undefined'){
                  if(generalForm.selected.length > 0){
                   document.getElementById(this.divName).innerHTML =
                            "<p style='color: red; font-size: 12px; font-weight: normal; height: 100%; margin-top: 100px'>Unable to make a chart from the current selections.</p>";
                  }else{
                       document.getElementById(this.divName).innerHTML = "";
                  }
                  return;
                }

                this.generalForm = generalForm;

                am4core.addLicense("CH208485191");
                let chart = am4core.create(divName, am4charts.XYChart);
                chart.id = chartId;
                // console.log("chartId=", chartId);

                 console.log("this.$store.state.settings.locale=", this.$store.state.settings.locale);
                let localeFile;
                switch(this.$store.state.settings.locale){
                    case "de-DE":
                        localeFile = am4lang_de_DE;
                    break;
                     case "en-US":
                        localeFile = am4lang_en_US;
                    break;
                    case "es-ES":
                        localeFile = am4lang_es_ES;
                    break;   
                    case "it-IT":
                        localeFile = am4lang_it_IT;
                    break;                                                  
                }
                chart.language.locale = localeFile;
                
                chart.padding(0, 10, 10, 10);
                chart.dateFormatter.dateFormat = "MMM dd, yyyy";
                chart.responsive.enabled = true;

                let title = chart.titles.create();
                title.text = decode(parsedData.title);
                title.fontSize = 11;
                title.fontWeight = "bold";
                title.marginTop = 4;
                title.marginBottom = 4;
                // the following line makes value axes to be arranged vertically.
                chart.leftAxesContainer.layout = "vertical";

                let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.id = "date-axis";
                dateAxis.renderer.grid.template.location = 0;
                dateAxis.renderer.ticks.template.length = 8;
                dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
                dateAxis.renderer.grid.template.disabled = false;
                dateAxis.renderer.ticks.template.disabled = true;
                dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
                dateAxis.renderer.labels.template.fontWeight = "normal";
                dateAxis.renderer.minLabelPosition = 0.01;
                dateAxis.renderer.maxLabelPosition = 0.99;
                dateAxis.keepSelection = false;
                dateAxis.minHeight = 30;
                dateAxis.dateFormats.setKey("day", "MMM dd, yyyy");
                dateAxis.tooltipDateFormat = ("day", "EEEE, MMM dd, yyyy");
                dateAxis.groupData = false;

                let mainAxis = chart.yAxes.push(new am4charts.ValueAxis());
                mainAxis.id = "main-axis";
                mainAxis.tooltip.disabled = false;
                mainAxis.renderer.baseGrid.disabled = true;
                mainAxis.height = am4core.percent(100);

                // mainAxis.renderer.gridContainer.background.fill = am4core.color("#000000");
                // mainAxis.renderer.gridContainer.background.fillOpacity = 0.05;
                mainAxis.renderer.inside = true;
                mainAxis.renderer.labels.template.verticalCenter = "bottom";
                mainAxis.renderer.labels.template.padding(2, 2, 2, 2);
                //mainAxis.renderer.maxLabelPosition = 0.95;
                mainAxis.renderer.fontSize = 10;
                
                mainAxis.title.fontWeight = "bold";
                mainAxis.title.fontSize = "11";
                mainAxis.renderer.labels.template.fontWeight = "normal";
                mainAxis.title.text = typeof decode !== 'undefined' ? decode(parsedData.valueAxisLabels[0]) : parsedData.valueAxisLabels[0];

                let names = parsedData.dataset.map((x) => decode(x.name));
                let colors = parsedData.dataset.map((x) => x.color);
                console.log("names=", names);
                console.log("colors=", colors);

                let chartData = parsedData.values.map((x) => {
                    let obj = {};
                    obj.date = moment(x[0], "YYYYMMDD").format("YYYY-MM-DD");
                    for (let i = 0; i < names.length; i++) {
                        obj[names[i]] = x[i + 1];
                    }
                    return obj;
                });
                console.log("chartData=", chartData);
                this.chartData = chartData;

                for (let i = 0; i < names.length; i++) {
                    let series = chart.series.push(new am4charts.LineSeries());
                    series.id = "series-" + i;
                    series.dataFields.dateX = "date";
                    series.clustered = false;
                    series.data = chartData;
                    series.dataFields.valueY = names[i];
                    series.yAxis = mainAxis;

                    generalForm.selected = [names[i]];
                    let invertDatesMultiplier = 1;
                    let noLegSwapping = true;
                    let ticker = names[i].includes("seasonal") ? names[i] : spreadTitle(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping);
                    console.log("ticker=", ticker);
                    series.name = ticker;

                    series.hiddenInLegend = false;
                    series.legendSettings.labelText = "[{color}]{name}:[/]";
                    series.legendSettings.itemValueText = "[{color}]{valueY}";
                    series.tooltipText = this.$store.state[this.namespace].chartParameters
                            .balloons
                            ? "[{color}]{name}: [/][{color}]{valueY}"
                            : "";
                    series.tooltip.getFillFromObject = true;

                    series.stroke = colors[i];
                    series.fill = colors[i];
                    series.showOnInit = false;
                    series.getFillFromObject = true;
                }

                chart.legend = new am4charts.Legend();
                chart.legend.position = "top";
                chart.legend.markers.template.disabled = false;
                chart.legend.useDefaultMarker = true;
                //chart.legend.fontSize = "11";
                chart.legend.fontWeight = "normal";

                //space out the legend from the chart container
                chart.legend.marginBottom = 10;

                //reduce vertical height between each entry if you want them
                //even closer together
                chart.legend.itemContainers.template.paddingTop = 0;
                chart.legend.itemContainers.template.paddingBottom = 0;

                chart.legend.valueLabels.template.textAlign = "left";

                // reduce the marker + container size so the text maintains alignment
                let marker = chart.legend.markers.template;
                marker.width = 8;
                marker.height = 8;

                // set the marker's corner radius to 1
                chart.legend.markers.template.children
                        .getIndex(0)
                        .cornerRadius(1, 1, 1, 1);

                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "left";
                chart.exporting.menu.verticalAlign = "top";

                chart.exporting.menu.items = [
                    {
                        label: "...",
                        menu: [
                            {
                                label: "Image",
                                menu: [
                                    {type: "png", label: "PNG"},
                                    {type: "jpg", label: "JPG"},
                                    {type: "svg", label: "SVG"},
                                    {type: "pdf", label: "PDF"},
                                ],
                            },
                            {
                                label: "Print",
                                type: "print",
                            },
                        ],
                    },
                ];

                chart.cursor = new am4charts.XYCursor();
                // Date format to be used in input fields
                // let inputFieldFormat = "yyyy-MM-dd";

                /*   let endDate = moment(chartData[chartData.length - 1].date);
                 let startDate = endDate.clone().subtract(1, 'years');
                 console.log("startDate=", startDate, " endDate=", endDate);  */

                this.$options.chart = chart;

                let inputDiv = document.querySelector('#' + this.namespace + 'InputDiv');
                console.log("inputDiv=", inputDiv);
                let inputHeight = inputDiv !== null ? inputDiv.offsetHeight : 0;
                console.log("inputHeight=", inputHeight);
                this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', inputHeight);

                // console.log("this.$parent.$parent.$parent.$parent=", this.$parent.$parent.$parent.$parent);
                this.$parent.$parent.$parent.$parent.setChartHeight();

                parsedData.eye = this.$store.state[this.namespace].chartParameters.eye;
                //  runPlotly(parsedData);

                let that = this;
                chart.events.on("ready", function () {
                    console.log("chart ready. chart=", chart);

                    if (that.$store.state[that.namespace].normalization === "on") {
                        that.addNormalization(chart);
                    }

                    if (typeof callRunPlotly !== "undefined") {
                        parsedData.eye =
                                that.$store.state[this.namespace].chartParameters.eye;
                        //  runPlotly(parsedData);
                    }

                    dateAxis.events.on("selectionextremeschanged", function () {
                        console.log(
                                "am4charts-basic-chart.vue createMultipleLineChart(): selectionextremeschanged that.namespace=",
                                that.namespace
                                );
                        let start = moment(dateAxis.minZoomed).format("YYYYMMDD");
                        console.log("start=", start);
                        let end = moment(dateAxis.maxZoomed).format("YYYYMMDD");
                        that.$store.commit(
                                that.namespace + "/chartParameters/setStart",
                                start
                                );
                        that.$store.commit(that.namespace + "/chartParameters/setEnd", end);
                    });
                });
            },
        },
    };
</script>
