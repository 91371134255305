<template>
    <div style=" width: 100%">

        <div v-if="['admin'].includes($store.state.user.role)">
        <hr>
          <b-button variant="dark" @click="toggleStateEditor" 
          style="height: auto; border: 1px solid darkgray;margin-left: 35px"
          v-bind:style="{color: 'white', backgroundColor: showStateEditor ? 'darkgray' : 'black'}">
          {{(showStateEditor ? "hide" : "show") + " State Editor" }}</b-button> 
        <hr>
        </div>

        <div style="border: 1px solid gray; padding: 3px; background-color: lightgray;">
            <div style="font-weight: bold; font-size: 1.35rem; color: darkblue; text-align: center">Saved Charts</div>
            <b-container fluid="xl" style="border: 1px solid darkgray; padding: 2px; background-color: whitesmoke; margin-bottom: 3px">
                <b-form inline v-on:submit.prevent="onSaveChart">
                    <b-input size="sm" v-model="saveName" style="border: 1px solid gray; font-size: 1.1rem; margin: 2px"></b-input>
                    <b-button v-if="saveShow" size="sm" variant="primary"
                              v-on:click="onSaveChart" style="margin: 2px; text-align: left; border: 1px solid darkgray;">Save</b-button>

                    <span v-if="overwriteShow" style="color:red; z-index:1; margin-left: 4px; font-size: 1.1rem">
                        already exists.
                        <b-button size="sm" v-on:click="overwrite" variant="danger" 
                                  style="text-align: left; margin: 2px; font-size: 1.1rem">overwrite</b-button>
                    </span>
                </b-form>
            </b-container>

            <div v-if="data !== null" style="background-color: white; margin-bottom: 0px; border: 1px solid darkgray; font-size: 1.1rem">
                <b-button size="sm" variant="secondary" @click="addNode"
                          style="margin: 4px; text-align: left; border: 1px solid darkgray; font-weight: bold">Add Folder</b-button>
                <vue-tree-list
                    @click="onClick"
                    @change-name="onChangeName"
                    @delete-node="onDel"
                    @add-node="onAddNode"
                    :model="data"
                    default-tree-node-name="new folder"
                    default-leaf-node-name="new chart"
                    v-bind:default-expanded="false"
                    add-leaf-node-disabled = "true"
                    style="font-size: 1.1rem"
                    >
                    <template v-slot:leafNameDisplay="slotProps">
                        <span>
                            {{ slotProps.model.name }} <span class="muted"></span>
                        </span>
                    </template>
                    <span class="icon" slot="addTreeNodeIcon"><i class="far fa-folder" style="color: black; margin: 0 4px 0 0"></i></span>
                    <span class="icon" slot="addLeafNodeIcon">＋</span>
                    <span class="icon" slot="editNodeIcon"><i class="far fa-edit" style="color: black; margin: 0 3px 0 0"></i></span>
                    <span class="icon" slot="delNodeIcon"><i class="far fa-trash-alt" style="color: black"></i></span>
                    <span class="icon" slot="leafNodeIcon"></span>
                    <span class="icon" slot="treeNodeIcon"><i class="far fa-folder" style="color: black; margin: 0 3px 0 0"></i></span>
                </vue-tree-list>
            </div>
        </div>

    </div>
</template>

<script>

    //import KTHeader from "@/view/layout/header/Header.vue";
    import Vue from 'vue';
    import { VueTreeList, Tree, TreeNode } from 'vue-tree-list';
    import { auth } from '../firebase';
    import * as fb from '../firebase';
    import generalForm from '@/store/generalFormModule';

    Vue.use(VueTreeList);

    export default {
        components: {
            // dynamodbUsers,
            //KTHeader, VueTreeList
        },
        mounted() {
            console.log("mounted() starting. this.namespace=", this.namespace);
        },
        props: {
            namespace: {
                type: String
            },
            defaultAddTreeNodeTitle: {
                type: String,
                default: 'Add Folder'
            }
        },
        data: function () {
            return{
                json: "",
                saveName: "",
                hide: 'hide',
                show: 'show',
                overwriteShow: false,
                saveShow: true,
                showState: false,
                selectedChart: "",

                layout: [],
                oldName: "",
                newTree: {},
                data: null,
                initialized: false
            }
        },
        computed: {
            email() {
               // console.log("this.$store.state.user.email=", this.$store.state.user.email);
                return this.$store.state.user.email;
            },
            showStateEditor(){
                return this.$store.state.showStateEditor;
            }
        },
        watch: {
            email: {
                immediate: true,
                handler(email) {
                    if (email !== "") {
                        fb.db.collection('saved-charts')
                                .doc(this.email).collection('layout').doc('layout-document')
                                .get()
                                .then((doc) => {
                                    if (doc.exists) {
                                       // console.log("Document data:", doc.data());
                                        this.layout = doc.data().layout;
                                    } else {
                                        // doc.data() will be undefined in this case
                                        console.log("No such document!");
                                        this.layout = [];
                                    }
                                }).catch((error) => {
                            console.log("Error getting document:", error);
                        });

                        fb.db.collection("tabs")
                                .doc(this.email).collection("tabs")
                                .get().then((querySnapshot) => {
                            const stateArray = querySnapshot.docs.map(doc => doc.data());
                            console.log("stateArray=", stateArray);

                            stateArray.forEach((module, index) => {
                                console.log("index=", index, " module=", module);

                                let namespace = "generalFormTab" + index;
                                if (typeof this.$store.state !== 'undefined' && !Object.keys(this.$store.state).includes(namespace)
                                        && module.sampleContract.length > 0) {
                                    this.$store.registerModule(namespace, generalForm);

                                    this.$store.commit(namespace + '/setGeneralForm', module);
                                    this.$store.commit(namespace + '/setModuleName', namespace);

                                    this.$store.dispatch(namespace + '/getContractListsFromServer');
                                }
                            });
                        });
                    }
                }
            },
            layout: function (layout) {
               // console.log("watching layout=", layout);
                // console.log("watching layout=", JSON.parse(JSON.stringify(layout)));
              // console.log("this.initialized=", this.initialized);
                if (!this.initialized && typeof layout !== 'undefined') {
                    this.buildTree(JSON.parse(JSON.stringify(layout)));
                    this.initialized = true;
                }
            },
            data: {
                handler(/*data*/) {
                   // console.log("watching data=", {...data});
                    if (this.oldName === "") {
                        //  if (this.initialized) {      // This is correct.
                        let temp = this.getNewTree().children;
                        if (typeof temp === 'undefined') {
                            temp = [];
                        }
                       // console.log("temp=", temp);
                        fb.db.collection('saved-charts')
                                .doc(this.email)
                                .collection('layout')
                                .doc('layout-document')
                                .set({"layout": temp})
                                .then(() => {
                                   // console.log('layout saved.')
                                });
                        //  }
                    }
                },
                deep: true
            },
            saveName: function (saveName) {
              //  console.log("watching saveName=", saveName);
                if (saveName !== "") {
                   // this.isSaveNameInUse(saveName);
                }
            }
        },
        methods: {
            toggleStateEditor(){
                this.$store.commit("setShowStateEditor", !this.showStateEditor);
            },
            newTabWithInitialState(){
                console.log("newTabWithInitialState() starting.");
            },
            buildTree(layout) {
               // console.log("buildTree() starting. layout=", layout);
                this.initialized = true;

                this.data = new Tree(layout);
                // console.log("temp2=", temp2);
            },
            getNewTree: function () {
               // console.log("getNewTree() starting.");
                let vm = this
                function _dfs(oldNode) {
                    // console.log("oldNode=", oldNode);

                    let newNode = {}

                    for (let k in oldNode) {
                        if (k !== 'children' && k !== 'parent') {
                            newNode[k] = oldNode[k]
                        }
                    }
                    // console.log("newNode=", newNode);

                    if (oldNode.children && oldNode.children.length > 0) {
                        newNode.children = []
                        for (let i = 0, len = oldNode.children.length; i < len; i++) {
                            newNode.children.push(_dfs(oldNode.children[i]))
                        }
                    }
                    return newNode
                }

                // vm.newTree = _dfs(vm.data);
                return _dfs(vm.data);
            },
            onDel(node) {
                console.log("onDel() starting. node=", node);
                if (node.isLeaf) {
                    if (node.name !== "") {
                        this.removeChart(node.name);
                        node.remove();
                    }
                } else {
                    if (node.children !== null) {
                        let numberOfChildren = node.children.length;
                        console.log("numberOfChildren=", numberOfChildren);
                        if (numberOfChildren === 0) {
                            node.remove();
                        } else {
                            alert("You can only delete empty folders.");
                        }
                    } else {
                        node.remove();
                    }
                }
            },
            onChangeName(params) {
                console.log("onChangeName() starting. params=", params, "this.oldName=", this.oldName);
                if (this.oldName === "") {
                    this.oldName = params.oldName;
                }
                if (typeof params.eventType !== 'undefined') {
                    console.log("Changing chart name.");
                    console.log("this.oldName=", this.oldName);

                    this.removeChart(this.oldName);
                    this.saveChart(params.newName);

                    this.oldName = "";
                    this.overwriteShow = false;

                     let temp = this.getNewTree().children;
                        if (typeof temp === 'undefined') {
                            temp = [];
                        }
                        console.log("temp=", temp);
                        fb.db.collection('saved-charts')
                                .doc(this.email)
                                .collection('layout')
                                .doc('layout-document')
                                .set({"layout": temp})
                                .then(() => {
                                    console.log('layout saved.')
                                });
                }
            },

            onAddNode(params) {
                console.log("onAddNode() starting. params=", params);
                params.addLeafNodeDisabled = true;
            },

            onClick(params) {
                console.log("onClick() starting. params=", params);
                if (params.isLeaf) {
                    this.getChart(params.name);
                    this.saveName = params.name;
                }
            },
            addNode() {
                console.log("addNode() starting.");
                let node = new TreeNode({name: 'new folder', isLeaf: false, addLeafNodeDisabled: true})
                if (!this.data.children) {
                    this.data.children = [];
                }
                this.data.addChildren(node);
                const firebaseUser = auth.currentUser;
                console.log("firebaseUser=", firebaseUser);
                this.$store.dispatch('fetchUserProfile', firebaseUser).then(user => {
                    console.log("then of fetchUserProfile, user=", user);
                });
            },
            addLeaf(name) {
                console.log("addLeaf() starting. name=", name, " this.data=", this.data);
                if (name !== "") {
                    let node = new TreeNode({name: name, isLeaf: true, addLeafNodeDisabled: true})
                    if (this.data !== null) {
                        if (!this.data.children) {
                            this.data.children = [];
                        }
                        this.data.addChildren(node);
                    }
                }
            },
            overwrite() {
                console.log("overwrite() starting.");
                this.overwriteShow = false;
                this.saveShow = true;
                this.saveChart(this.saveName);
            },
            onSaveChart() {
                console.log("onSaveChart() starting.");
                if (this.saveName !== "") {
                    this.isSaveNameInUse(this.saveName)
                            .then(inUse => {
                                if (!inUse) {
                                    this.saveChart(this.saveName);
                                    this.addLeaf(this.saveName);
                                }
                            });
                } else {
                    alert("Please enter a name for this chart.");
                }
            },
            isSaveNameInUse: function (saveName) {
                console.log("isSaveNameInUse() starting. saveName=", saveName);
                let key = saveName.replace(/\./g, 'Period').replace(/\//g, 'ForwardSlash');
                return new Promise((resolve) => {
                    fb.db.collection('saved-charts')
                            .doc(this.email).collection('charts').doc(key)
                            .get()
                            .then((doc) => {
                                if (doc.exists) {
                                    console.log("Document already exists!");
                                    this.overwriteShow = true;
                                    this.saveShow = false;
                                    resolve(true);
                                } else {
                                    console.log("Document does not exist!");
                                    this.overwriteShow = false;
                                    this.saveShow = true;
                                    resolve(false);
                                }
                            }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
                });
            },
            saveChart: function (saveName) {
                console.log("saveChart() starting.");
                let language = "en";
                console.log("saveName=" + saveName);

                language = "en";
                console.log("language=" + language);
                let subscribersOnlyMessage, enterNameMessage;
                if (language == "en") {
                    subscribersOnlyMessage = "Only subscribers can save charts.";
                    enterNameMessage = "Please enter a name for this chart.";
                } else if (language == "de") {
                    subscribersOnlyMessage = "Nur Abonnenten kÃ¯Â¿Â½nnen Diagramme speichern.";
                    enterNameMessage = "Bitte geben Sie einen Namen fÃ¯Â¿Â½r diese Tabelle ein.";
                } else if (language == "es") {
                    subscribersOnlyMessage = "SÃ¯Â¿Â½lo los suscriptores pueden guardar grÃ¯Â¿Â½ficos.";
                    enterNameMessage = "Introduzca un nombre para esta tabla.";
                }

                if (this.email == "" || this.email == "guest") {
                    alert(subscribersOnlyMessage);
                } else if (saveName == "") {
                    alert(enterNameMessage);
                } else {
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    delete generalForm.browserSideOnly;
                    delete generalForm.playback;
                    let key = saveName.replace(/\./g, 'Period').replace(/\//g, 'ForwardSlash');
                    console.log("key=", key);
                    generalForm.key = key;
                    generalForm.saveName = saveName;
                    //    generalForm.language = $('#language').val();
                    console.log("generalForm", generalForm);

                    fb.db.collection('saved-charts')
                            .doc(this.email)
                            .collection('charts')
                            .doc(key)
                            .set({generalForm})
                            .then(() => {
                                console.log('chart saved.');
                            });
                }
            },
            removeChart: function (name) {
                console.log("removeChart() starting. name=", name);
                let key = name.replace(/\./g, 'Period').replace(/\//g, 'ForwardSlash');
                console.log("key=" + key);
                fb.db.collection("saved-charts").doc(this.email).collection("charts").doc(key).delete().then(() => {
                    console.log("chart deleted.");
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });
            },
            getChart: function (name) {
                console.log("getChart() starting. name=", name);
                let key = name.replace(/\./g, 'Period').replace(/\//g, 'ForwardSlash');
                console.log("key=", key);
                fb.db.collection('saved-charts')
                        .doc(this.email).collection('charts').doc(key)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                console.log("Document data:", doc.data());
                                this.selectedChart = key;
                                this.$emit('newTab', doc.data().generalForm);
                            } else {
                                // doc.data() will be undefined in this case
                                console.log("No such document!");
                            }
                        }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }
        }
    }
</script>
