<template>

    <b-form inline style="padding: 1px;">
        <b-button-group style="border: 1px solid gray; padding: 2px; margin: 0px; background-color: #E8E8E8">

            <span style="padding: 8px 2px 0 5px; font-weight: bold">and&nbsp;</span> 
            <b-form-select style="border:1px solid gray; margin-top: 1px"
                           v-model="parameter" :options="parameterOptions"></b-form-select> 

            <b-form-select style="border:1px solid gray; margin-top: 1px"
                           v-model="comparator" :options="comparatorOptions"></b-form-select> 

            <b-input style="width: 70px; border:1px solid gray; margin-top: 1px"
                     v-model.number="value" @keydown.enter.prevent="onClick">
            </b-input>

            <b-button v-if="index > 0 || numberOfConditions > 1" variant="warning" 
                      v-on:click="removeCondition" style="margin: 1px; border: 1px solid darkgray">remove</b-button>

            <b-button v-if="index === numberOfConditions-1" variant="primary"
                      v-on:click="addCondition" style="margin: 1px; border: 1px solid darkgray">add condition</b-button>

        </b-button-group>
    </b-form>

</template>

<script>
    module.exports = {
        mounted: function () {},
        props: ['index', 'namespace'],
        data() {
            return {
                parameterOptions: [
                    {value: 'appd', text: 'appd'},
                    {value: 'percentProfitable', text: 'percentProfitable'},
                    {value: 'rrr', text: 'rrr'},
                    {value: 'avgMin', text: 'avg mae'},
                    {value: 'worstMin', text: 'worst mae'},
                    {value: 'avgMax', text: 'avg mpe'},
                    {value: 'bestMax', text: 'best mpe'},
                    {value: 'rsi', text: 'rsi'},
                    {value: 'open', text: 'open'},
                    {value: 'close', text: 'close'}
                ],
                comparatorOptions: [
                    {value: '<', text: '<'},
                    {value: '>', text: '>'}
                ]
            }
        },
        computed: {
            numberOfConditions() {
                return this.$store.state[this.namespace].search.userConditions.length;
            },
            parameter: {
                get() {
                    return this.$store.state[this.namespace].search.userConditions[this.index].parameter;
                },
                set(parameter) {
                    let arr = JSON.parse(JSON.stringify(this.$store.state[this.namespace].search.userConditions));
                    let newObj = arr[this.index];
                    newObj.parameter = parameter;
                    arr[this.index] = newObj;
                    console.log("arr=", arr);
                    this.$store.commit(this.namespace + '/search/setUserConditions', arr);

                }
            },
            comparator: {
                get() {
                    return this.$store.state[this.namespace].search.userConditions[this.index].comparator;
                },
                set(comparator) {
                    let arr = JSON.parse(JSON.stringify(this.$store.state[this.namespace].search.userConditions));
                    let newObj = arr[this.index];
                    newObj.comparator = comparator;
                    arr[this.index] = newObj;
                    this.$store.commit(this.namespace + '/search/setUserConditions', arr);

                }
            },
            value: {
                get() {
                    return this.$store.state[this.namespace].search.userConditions[this.index].value;
                },
                set(value) {
                    if (value !== "") {
                        let arr = JSON.parse(JSON.stringify(this.$store.state[this.namespace].search.userConditions));
                        let newObj = arr[this.index];
                        newObj.value = value;
                        arr[this.index] = newObj;
                        this.$store.commit(this.namespace + '/search/setUserConditions', arr);

                    }
                }
            }
        },
        methods: {
            onClick() {
                this.$emit('update-item');
            },
            addCondition: function () {
                console.log("addCondition() starting.");
                let arr = JSON.parse(JSON.stringify(this.$store.state[this.namespace].search.userConditions));
                let newObj = arr[arr.length - 1];
                arr.push(newObj);
                this.$store.commit(this.namespace + '/search/setUserConditions', arr);
                // updateState();
            },
            removeCondition() {
                console.log("removeCondition() starting.");
                let arr = JSON.parse(JSON.stringify(this.$store.state[this.namespace].search.userConditions));
                arr.splice(this.index, 1);
                this.$store.commit(this.namespace + '/search/setUserConditions', arr);

            },
        }
    }
</script>
