<template>
    <div>
        <table style="border: 1px solid gray; background-color: lightgray;">
            <tr>
                <td style="padding: 3px;">
            <b-form-row align-h="center"  style="padding: 4px">
                <b-col cols="2" style="white-space: nowrap; margin-left: 7px; margin-right: 10px">  
                    <legs v-bind:max="maxLegs" v-bind:namespace="namespace"></legs>    
                </b-col>
                <b-col v-if="$store.state[namespace].legs > 1 && $store.state[namespace].generator !== 'PercentProfitableMapGenerator'">  
                    <intracommodity v-bind:namespace="namespace"></intracommodity>
                </b-col>  
                <b-col style="">  
                    <slot name="y1"></slot>
                </b-col>
                <b-col cols="5" style="margin: 6px 5px 0 0">  
                    <slot name="data-source"></slot>
                </b-col>
            </b-form-row> 

        <table>
            <tr v-for="(number, index) in $store.state[namespace].legs" v-bind:key="index" style="white-space: nowrap;">  
             <td>   <mult v-if="showMultSelector" v-bind:namespace="namespace" v-bind:index="index"></mult> </td>
               <td> <position v-if="showPositionForSingleLeg || legs > 1" v-bind:namespace="namespace" v-bind:index="index"></position></td>
              <td>  <commodity v-bind:namespace="namespace" v-bind:index="index"></commodity></td>
              <td>  <month v-bind:namespace="namespace" v-bind:index="index"></month></td>
              <td>  <unit-move v-bind:namespace="namespace" v-bind:index="index"></unit-move></td> 
            </tr>
        </table>
            </td>
            <td style="padding: 2px; vertical-align: bottom">
            <slot name="contract-aligner"></slot>
            </td>
            </tr>
        </table>

    </div>
</template>

<script>

    import intracommodity from '@/components/intracommodity.vue';
    import commodity from '@/components/commodity.vue';
    import month from '@/components/month.vue';
    import position from '@/components/position.vue';
    import mult from '@/components/mult.vue';
    import unitMove from '@/components/unit-move.vue';
    import legs from '@/components/legs.vue';

    export default {
        components: {
            commodity, month, legs, intracommodity, mult, unitMove, position
        },
        mounted() {
           // console.log("commodity-box.vue mounted() starting.");
           // console.log("this.namespace=", this.namespace);
        },
        props: {
            namespace: {
                type: String,
                default: ""
            },
            maxLegs: {
                type: Number,
                default: 4
            },
            showPositionForSingleLeg: {
                type: Boolean,
                default: true
            },
            showMultSelector: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            legs() {
                return this.$store.state[this.namespace].legs;
            }
        }
    }
</script>

