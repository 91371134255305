<template>
    <div style="text-align: center; color: black; background-color: white;  border: 1px solid gray; font-weight: normal; padding: 0px 1px 1px 1px"
         v-bind:style="{border: ($store.state[namespace].program === 'Calculator' && $store.state[namespace].legs === 1)  ? '0px solid gray' : '1px solid gray' }">
        
        <div v-if="$store.state[namespace].legs > 1" style="padding: 1px; text-align: center">                                                         
          <b> {{title}}</b>
          <contract-aligner v-bind:namespace="namespace" ></contract-aligner>     
        </div>

        <div v-if="!multiple && $store.state[namespace].program !== 'Calculator'" style=" padding: 1px">                                                         
            <div v-if="$store.state[namespace].legs === 1" style="font-weight: bold;">                                                         
                Selector
            </div>
            <contract-selector-single-select v-bind:namespace="namespace"
                                             v-bind:open-only="openOnly"></contract-selector-single-select>
        </div>
        <div v-if="multiple && $store.state[namespace].program !== 'Calculator'">                                                         
            <div v-if="$store.state[namespace].program !== 'Calculator'" style="font-weight: bold;">
                Selector
            </div>
            <contract-selector v-bind:namespace="namespace"></contract-selector>  
        </div>
    </div>
</template>

<script>

    import contractSelectorSingleSelect from '@/components/contract-selector-single-select';
    import contractSelector from '@/components/contract-selector';
    import contractAligner from '@/components/contract-aligner';

    export default {
        components: {
            contractSelectorSingleSelect,
            contractSelector,
            contractAligner
        },
        mounted: function () {
            // console.log("contract-aligner-selector.vue mounted() starting. this.openOnly=", this.openOnly);
        },
        props: {
            namespace: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: ""
            },
            multiple: {
                type: Boolean
            },
            openOnly: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

