var calculatorInitialState = {
    "instrument": "future",
    "legs": 1,
    "sampleContract": [
        "C2021U",
        "C2021Z",
        "C2022H",
        "C2022K"
    ],
    "selected": [],
    "intracommodity": false,
    "p": [
        1,
        -1,
        1,
        1
    ],
    "unitMove": [
        50,
        50,
        50,
        50
    ],
    "unit": "",
    "y1": 15,
    "generator": "SeasonalsGenerator",
    program: "Calculator",
  //  "open": "2021-03-21",
  //  "close": "2021-04-21",
   /* "openMonth": "March",
    "openDate": "21",
    "closeMonth": "April",
    "closeDate": "21", */
    "mult": [
        1,
        1,
        1,
        1
    ],
    "spreadP": 1,
    "overwriteShow": false,
    "addProfitLoss": false,
    "rsiSource": "C2021U",
    "showTradingPeriod": true,
    "showAligned": true,
    "user": "A4A-user0",
    "password": "M1-K8xQgmR",
    "constrainContractAligner": false,
    "study": "calculator",
    "watchList": [],
    "longtermChartColors": "yearly",
    "numberOfContractsApart": "0",
    "language": "en",
    "chartParameters": {
        "dataType": "monthly",
        "legendLocation": "bottom",
        "balloons": true,
        "height": 600,
        "page": 1,
        "showMapHover": false,
        "showMapHoverLocationOnSeasonal": false,
        "parameterToMap": "percentUp",
        "start": "",
        "end": "",
        "lastChartDate": "",
        "levels": [],
        "numberOfCharts": "",
        "initialZoom": true,
        "skipEmptyPeriods": false
    },
    "playback": {
        "i": null
    },
    "search": {
        "sortParameter": "rrr",
        "legs": 1,
        "position": "either",
        "direction": "desc",
        "source": "singleCommodity",
        "minDays": "2",
        "maxDays": "100",
        "userConditions": [
            {
                "parameter": "percentProfitable",
                "comparator": ">",
                "value": 70
            }
        ]
    },
    "programName": "Calculator.html",
    "legendEnabled": false,
    "balloons": false
};

export {calculatorInitialState};