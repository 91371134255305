<template>
    <div style="border: 0px solid #505050; width: 100%; padding-bottom: 5px;">
        <table v-if="trades.length > 0" id="trades" style="width: 100%;">
            <tr>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'ticker' ? 'red' : 'teal' }">ticker (click for chart)</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'position' ? 'red' : 'teal' }">position</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'open' ? 'red' : 'teal' }">open</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'close' ? 'red' : 'teal' }">close</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'avgDays' ? 'red' : 'teal' }">avg days</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'percentUp' ? 'red' : 'teal' }">% profitable</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'appd' ? 'red' : 'teal' }">appd</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'rrr' ? 'red' : 'teal' }">avg mpe/avg mae</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'avgMax' ? 'red' : 'teal' }">avg mpe</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'bestMax' ? 'red' : 'teal' }">best mpe</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'avgMin' ? 'red' : 'teal' }">avg mae</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'worstMin' ? 'red' : 'teal' }">worst mae</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'rsi' ? 'red' : 'teal' }">rsi</th>
            </tr>
            <tr v-for="n in trades" v-bind:key="n.id" style="white-space: nowrap;">
                <td v-bind:style="{textAlign: 'center', color: tickerColor[n.id]}" @click="getChart" @mouseover="mouseOver(n.id)"><a>{{n.ticker}}</a></td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'position' ? 'red' : 'black' }">{{n.position}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'open' ? 'red' : 'black' }">{{n.open}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'close' ? 'red' : 'black' }">{{n.close}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'close' ? 'red' : 'black' }">{{Math.round(1000*n.avgDays)/1000}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'percentProfitable' ? 'red' : 'black' }">{{Math.round(10*n.percentProfitable)/10}}</td>
                <td v-bind:style="{textAlign: 'right', color: sortParameter == 'appd' ? 'red' : 'black' }">{{displayMoneyFormat(Math.round(100*n.appd)/100)}}</td>
                <td v-bind:style="{textAlign: 'right', color: sortParameter == 'rrr' ? 'red' : 'black' }">{{Math.abs(Math.round(100*n.rrr))/100}}</td>
                <td v-bind:style="{textAlign: 'right', color: sortParameter == 'avgMax' ? 'red' : 'black' }">{{displayMoneyFormat(Math.round(100*n.avgMpe)/100)}}</td>
                <td v-bind:style="{textAlign: 'right', color: sortParameter == 'bestMax' ? 'red' : 'black' }">{{displayMoneyFormat(Math.round(100*n.worstMpe)/100)}}</td>
                <td v-bind:style="{textAlign: 'right', color: sortParameter == 'avgMin' ? 'red' : 'black' }">{{displayMoneyFormat(Math.round(100*n.avgMae)/100)}}</td>
                <td v-bind:style="{textAlign: 'right', color: sortParameter == 'worstMin' ? 'red' : 'black' }">{{displayMoneyFormat(Math.round(100*n.worstMae)/100)}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'rsi' ? 'red' : 'black' }">{{n.rsi}}</td>
            </tr>
        </table>      

        <b-button-group style="margin: 7px 10px 0 0">
            <span style="; padding: 6px 3px 0 10px">page:
            </span>
            <b-pagination style="margin-bottom: 0px; margin-right: 10px; border: 0px solid gray; padding: 0px"
                          v-model="page"
                          :total-rows="totalPages*rowsPerPage"
                          :per-page="rowsPerPage">
            </b-pagination>

            <span style=""> &nbsp;rows/page:
                <b-form-select style="border:1px solid gray; width: 65px;" v-model="rowsPerPage" :options="rowsPerPageOptions"></b-form-select>
            </span>
        </b-button-group>
    </div>
</template>

<style>
    #trades {
       /* font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
        font-weight: normal;
        color: darkgray;
        border-collapse: collapse;
        width: 100%;
    }

    #trades td {
        padding: 2px 14px 2px 14px;
        border: 1px solid #ddd;
    }

    #trades tr:nth-child(even){background-color: #f2f2f2;}

    #trades tr:hover {background-color: #ddd;}

    #trades th {
        border: 1px solid #ddd;
        padding: 4px;
        text-align: center;
        background-color: teal;
        color: white;
    }

</style>

<script>
    var currentRequest;

    import $ from "jquery";
    import moment from 'moment';
    import {tickerToGeneralForm} from "../../../js/main";
    import {tradeMapsInitialState} from '../../programs/TradeMaps/trade-maps-initial-state';

    export default {
        components: {
        },
        mounted() {
            console.log("search-output.vue mounted() starting.");

            if (this.activeModuleName === this.namespace && !this.initialized && typeof this.query !== 'undefined') {
                this.initialized = true;
                this.getTrades(this.query);
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                initialized: false,
                trades: [],
                numberOfTrades: 0,
                totalPages: 1,
                rowsPerPageOptions: Array(10).fill(null).map((x, i) => ({value: 10 * (i + 1), text: 10 * (i + 1)})),
                tickerColor: []
            }
        },
        computed: {
            sortParameter: {
                get() {
                    return this.$store.state[this.namespace].search.sortParameter;
                },
                set(sortParameter) {
                    this.$store.commit(this.namespace + '/search/setSortParameter', sortParameter);
                }
            },
            rowsPerPage: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.rowsPerPage;
                },
                set(rowsPerPage) {
                    this.$store.commit(this.namespace + '/chartParameters/setRowsPerPage', rowsPerPage);
                }
            },
            page: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.page;
                },
                set(page) {
                    this.$store.commit(this.namespace + '/chartParameters/setPage', page);
                }
            },
            query() {
                return this.$store.state[this.namespace].search.query;
            },
            activeModuleName() {
                return  this.$store.getters['activeModuleName'];
            }
        },
        watch: {
            query: function (query) {
                console.log("watch: query=", query);
                this.getTrades(query);
            },
            activeModuleName: function (activeModuleName) {
                console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace,
                        " this.initialized=", this.initialized + " activeModuleId=", this.$store.getters['activeModuleId']);
                // console.log("this.query=", this.query);
                if (activeModuleName === this.namespace && !this.initialized && typeof this.query !== 'undefined') {
                    this.initialized = true;
                    this.getTrades(this.query);
                }
            }
        },
        methods: {
            displayMoneyFormat: function (number) {
                // console.log("number=", number);
                var returnValue;
                if (isNaN(number) || null == number) {
                    // console.log("missing data.");
                    returnValue = "------\u00A0\u00A0\u00A0 ";
                } else {
                    returnValue = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(number);
                }
                // console.log("returnValue=", returnValue);
                return returnValue;
            },
            mouseOver(index) {
                // console.log("mouseOver() starting. index=", index);
                let arr = Array(this.numberOfTrades).fill(null).map(() => "darkblue");
                arr[index] = "red";
                this.tickerColor = arr;
            },
            getChart(element) {
                console.log("getChart() starting.");
                let ticker = element.target.text;
                console.log("ticker=", ticker);

                let trade = this.trades.find(x => x.ticker === ticker);
                console.log("trade=", trade);
                let open = moment(trade.open, "MMM DD, YYYY").format("YYYY-MM-DD");
                let close = moment(trade.close, "MMM DD, YYYY").format("YYYY-MM-DD");
                console.log("open=", open, " close=", close);

                let generalForm = tickerToGeneralForm(ticker);
                generalForm.open = open;
                generalForm.close = close;

                generalForm.openMonth = moment(open).format("MMMM");
                generalForm.openDate = moment(open).format("DD");
                generalForm.closeMonth = moment(close).format("MMMM");
                generalForm.closeDate = moment(close).format("DD");

                generalForm.showTradingPeriod = true;
                generalForm.chartParameters = {};
                generalForm.chartParameters.parameterToMap = this.$store.state[this.namespace].search.sortParameter;

                console.log("generalForm=", generalForm);

                let mergedForm = {...tradeMapsInitialState, ...generalForm};
                this.$emit('newTab', mergedForm);
            },
            getTrades: function (query) {
                console.log("getTrades() starting.");
                this.$store.commit(this.namespace + '/setLoading', true);

                if (query !== false) {
                    this.trades = [];
                    //  let url = "http://localhost:8080/SearchTradesServlet?query=" + encodeURIComponent(query);
                    //  let url = "http://46.101.206.208/SearchTradesServlet?query=" + encodeURIComponent(query);
                    //  let url = "https://danscarr.com/SearchTradesServlet?query=" + encodeURIComponent(query);
                    let url = this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/SearchTradesServlet?query=" + encodeURIComponent(query);
                    var that = this;
                    currentRequest = $.ajax({
                        url: url,
                        type: "GET",
                        beforeSend: function () {
                            console.log("beforeSend starting.");
                           // console.log("currentRequest=", currentRequest);
                            if (currentRequest != null) {
                                console.log("aborting previous request.");
                                currentRequest.abort();
                            }
                        },
                        success: function (data) {
                            // console.log("data=", data);
                            let temp = JSON.parse(data).trades;
                            console.log("temp=", temp);
                            that.$store.commit(that.namespace + '/setLoading', false);

                            let result = temp.map(function (el, index) {
                                var o = Object.assign({}, el);
                                let spread = o.spread;
                                // console.log("o=", o);
                                if (spread !== null) {
                                    // let contract = spread.replace(/ /g, '').split("/");
                                    // console.log("contract=", contract);

                                    o.id = index;
                                    let open = moment(el.open, "YYYYMMDD");
                                    let close = moment(el.close, "YYYYMMDD");
                                    //  let days = el.days;
                                    o.open = open.format("MMM D, YYYY");
                                    o.close = close.format("MMM D, YYYY");
                                    o.avgDays = el.avgDays;
                                    o.avgMae = el.avgMin;
                                    o.worstMae = el.worstMin;
                                    o.avgMpe = el.avgMax;
                                    o.worstMpe = el.bestMax;
                                    o.rrr = el.rrr;

                                    o.ticker = el.spread;

                                    delete o.avgMin;
                                    delete o.worstMin;
                                    delete o.avgMax;
                                    delete o.bestMax;
                                    // console.log("o=", o);
                                } else {
                                    console.log("no open positions. o.ticker=", o.ticker);
                                    o = "null";
                                }
                                return o;
                            });
                            console.log("result=", result);
                            var filteredResult = result.filter(x => x !== "null");
                            console.log("filteredResult=", filteredResult);
                            that.trades = filteredResult;
                            that.numberOfTrades = JSON.parse(data).numberOfTrades;
                            that.totalPages = Math.ceil(that.numberOfTrades / that.rowsPerPage);
                            console.log("numberOfTrades=", that.numberOfTrades, "  that.totalPages=", that.totalPages);

                            that.tickerColor = Array(that.numberOfTrades).fill(null).map(() => "darkblue");
                        },
                        fail: function (data) {
                            console.log("call failed.");
                            that.$store.commit(that.namespace + '/setLoading', false);
                            console.log(data);
                        }
                    });
                }
            }
        }
    };

</script>
