<template>
  <div style="border: 0px solid darkgray; height: 100%; width: fit-content; margin-top: 0px;">
    <table>
      <tr>
        <td colspan=2 style="text-align: center">
           <b-button variant="primary" style="border: 1px solid darkgray; margin-top: 10px;"
    v-on:click="transfer()">Transfer</b-button>
    </td>
    </tr>
      <tr>
        <td style="vertical-align: top; text-align: center; padding: 20px; width: 50%">
          <h3 style='color: darkblue'>Source</h3>
          <saved-charts-admin v-bind:ref="'source'"></saved-charts-admin>
          
  <!--  <b-button variant="success" style="border: 1px solid darkgray; margin: 5px;"
    v-on:click="download()">Download</b-button> -->
        </td>
        <td style="vertical-align: top; text-align: center; padding: 20px; width: 50%">
          <h3 style='color: darkblue'>Target</h3>
          <saved-charts-admin-firebase v-bind:ref="'target'"></saved-charts-admin-firebase>
      
     <!--  <b-button variant="success" style="border: 1px solid darkgray; margin: 5px;"
    v-on:click="upload()">Upload</b-button> -->
     </td>
  </tr>
  </table>
  </div>
</template>

<script>

    import savedChartsAdmin from '@/components/saved-charts-admin';
    import savedChartsAdminFirebase from '@/components/saved-charts-admin-firebase';
   // import $ from "jquery";

    export default {
      components: {
            savedChartsAdmin, savedChartsAdminFirebase
        },
        mounted() {
            console.log("saved-charts-admin-output.vue mounted() starting.");
        },
        props: ['namespace'],
        methods: {
          download(){
            console.log("download() starting.");
            this.$refs.source.download();
          },
          upload(){
            console.log("upload() starting.");

          },
          transfer(){
            console.log("transfer() starting. this.$refs=", this.$refs);
            let sourceCharts = this.$refs.source.savedCharts.sort((a, b) => a.key.localeCompare(b.key));
            console.log("sourceCharts=", sourceCharts.map(x => x.saveName));
            
            this.$refs.target.savedCharts = sourceCharts;
            this.$refs.target.saveCharts();
          }
        }       
    };

</script>

