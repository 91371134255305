<template>
    <div style="text-align: left; width: fit-content; background-color: white;" id="inputDiv">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <p style="padding: 4px">
                    Metti qui le istruzioni.
                </p>
            </b-sidebar>
        </div>
        <h2 style="text-align: left;">Forward Curves
        </h2>
        <b-form inline style="border: 1px solid gray; padding: 2px; white-space: nowrap; background-color: #E8E8E8; ">
            <commodity v-bind:namespace="namespace" index="0"></commodity>

            <span  style="margin-left: 15px; font-weight: bold"> chart type: 
                <b-form-select v-model="forwardCurveStudy" :options="studyOptions" style="border:1px solid gray;" ></b-form-select>
            </span>

            <span v-if="this.forwardCurveStudy !== 'averages'" style="margin-left: 15px; font-weight: bold"> show curves from the past 
                <b-form-select v-model="y1" :options="yearOptions"  
                               style="border:1px solid gray; " ></b-form-select>
                years</span> 


            <b-button-group style="border: 1px solid gray; padding: 1px; white-space: nowrap; background-color: #E8E8E8; margin-right: 25px; margin-left: 25px"> 
                <forward-curve-normalization v-bind:namespace="namespace"></forward-curve-normalization>
            </b-button-group>

            <b-button-group style="border: 1px solid gray; padding: 1px; white-space: nowrap; background-color: #E8E8E8; margin-right: 3px"> 
                <b-button v-on:click="$root.$emit('change-period', '1')" style="margin-left:0px;">1 yr</b-button>
                <b-button v-on:click="$root.$emit('change-period', '2')" style="margin-left:2px;">2 yr</b-button>
                <b-button v-on:click="$root.$emit('change-period', '15')"  style="margin-left: 1px;">MAX</b-button>
            </b-button-group>

            <b-button-group style="border: 1px solid gray; padding: 1px; white-space: nowrap; background-color: #E8E8E8; margin-right: 2px"> 
                <balloon-toggle v-bind:namespace="namespace" style="margin: 1px"></balloon-toggle>

                <b-button v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray; margin-left: 2px">Help</b-button>
            </b-button-group>
        </b-form>

    </div>
</template>

<script>
    import {getCommoditiesObject} from '../../../js/main';
    import commodity from '@/components/commodity';
    import balloonToggle from '@/components/balloon-toggle';
    import forwardCurveNormalization from '@/components/forward-curve-normalization';

    export default {
        components: {
            commodity, forwardCurveNormalization, balloonToggle
        },
        mounted: function () {
            console.log("forward-curves-input.vue starting.");

             const observer = new ResizeObserver(entries => {
               // console.log("ResizeObserver() starting.");
                for (let entry of entries) {
                   // console.log("entry=", entry);
                    // Now do something with the resized element
                   console.log("entry.contentRect.height=", entry.contentRect.height);
                   this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                }
            });
            observer.observe(document.querySelector('#' + this.namespace + 'InputDiv')); 
        },
        props: ['namespace'],
        data: function () {
            return {
                contracts: [],
                monthsWithAverageOrCurrent: "",
                series: [],
                data: [],
                dates: [],
                lastDate: "",
                studyOptions: [
                    {value: 'aligned', text: 'aligned'},
                    {value: 'averages', text: 'averages'},
                    {value: 'unaligned', text: 'unaligned'}
                ]
            }
        },
        computed: {
            commodity: {
                get() {
                    let commodity = this.$store.getters[this.namespace + '/c'][0];
                    console.log("commodity=", commodity);
                    return  commodity;
                },
                set(commodity) {
                    this.$store.dispatch(this.namespace + '/changeCommodityLeg', {commodity: commodity, index: 0});
                }
            },
            commodityOptions() {
                console.log("getCommoditiesObject()=", getCommoditiesObject());
                return Object.values(getCommoditiesObject()).map(x => {
                    return {value: x.symbol, text: x.name};
                });
            },
            y1: {
                get() {
                    return this.$store.state[this.namespace].y1;
                },
                set(y1) {
                    this.$store.commit(this.namespace + '/setY1', y1);
                }
            },
            yearOptions() {
                let years = this.forwardCurveStudy === 'unaligned' ? [5, 10, 15] : [5, 15];
                return years.map(x => {
                    return {value: x, text: x};
                });
            },
            forwardCurveStudy: {
                get() {
                    console.log("this.$store.state[this.namespace].forwardCurveStudy=", this.$store.state[this.namespace].forwardCurveStudy);
                    return this.$store.state[this.namespace].forwardCurveStudy;
                },
                set(forwardCurveStudy) {
                    this.$store.commit(this.namespace + '/setForwardCurveStudy', forwardCurveStudy);
                }
            }
        }
    };

</script>
