<template>
    <div>
        <table border=0 style="font-weight: bold; border: 1px solid gray; color: black; background-color: lightgray">
            <tr>
                <td style="">
            <b-form-group label="" v-slot="{ ariaDescribedby }" style="margin: 5px 0 0 12px; font-weight: bold">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="buySell"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    size="sm"></b-form-radio-group>
            </b-form-group>
            </td>
            </tr>
            <tr>
                <td style="padding: 0 0 4px 4px">
            <open-close-selector v-bind:namespace="namespace" style=""></open-close-selector>
            </td>
            </tr>
        </table>

    </div>
</template>

<script>
    import openCloseSelector from '@/components/open-close-selector';

    export default {
        components: {
            openCloseSelector,
        },
        mounted() {
           // console.log("trade-specs.vue mounted() starting.");
           // console.log("this.namespace=", this.namespace);
        },
        props: ['namespace', 'title'],
        data() {
            return {
                selected: 'first',
                options: [
                    {text: 'buy', value: 'buy'},
                    {text: 'sell', value: 'sell'}
                ]
            }
        },
        computed: {
            buySell: {
                get() {
                    return this.$store.state[this.namespace].buySell;
                },
                set(buySell) {
                    this.$store.commit(this.namespace + '/setBuySell', buySell);
                }
            },
        }
    }
</script>

