<template>
    <span>
        <b-button v-on:click="toggle" size="sm" style="font-weight: bold; border: 1px solid gray; ">
            {{label}}</b-button>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        methods: {
            toggle: function () {
                let balloons=this.$store.state[this.namespace].chartParameters.balloons;
               // console.log("test=", test);
                console.log("this.namespace=", this.namespace);
                console.log("balloons=", !balloons);
                this.$store.commit(this.namespace + '/chartParameters/setBalloons', !balloons);
            }
        },
        computed: {
            label() {
                return this.$store.state[this.namespace].chartParameters.balloons ? "hide labels" : "show labels";
            }
        }
    }
</script>
