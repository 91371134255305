<template>
    <span>
        <b-button id="popover-target-volume-profile" size="sm" style="color: white"
                  v-bind:style="{backgroundColor: addVolumeProfile === true ? 'darkgray' : 'black' }">
            Vol. Profile
        </b-button>
        <b-popover target="popover-target-volume-profile" triggers="click blur" placement="bottom" ref="popover">
            <table style="padding: 2px; text-align: right; font-family: verdana">
                <tr>
                    <td style=" font-weight: bold; font-size: 1rem;">add Volume Profile:
                <b-form-select style="border:1px solid gray; width: auto;  font-weight: normal"
                               v-model="addVolumeProfile" :options="options"></b-form-select>

                </td>
                </tr>
                <tr v-if="addVolumeProfile">
                    <td align="right" style='padding-top: 3px;  font-weight: bold; font-size: 1rem;'>
                        number of columns:&nbsp;
                <b-form-select style="border:1px solid gray; width: auto;  font-weight: normal"
                               v-model="volumeProfileColumns" :options="volumeProfileColumnsOptions"></b-form-select>

                </td>
                </tr>
            </table>                         
        </b-popover>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ],
                volumeProfileColumnsOptions: Array(100).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
            };
        },
        computed: {
            addVolumeProfile: {
                get() {
                    return this.$store.state[this.namespace].addVolumeProfile;
                },
                set(addVolumeProfile) {
                    this.$store.commit(this.namespace + '/setAddVolumeProfile', addVolumeProfile);
                }
            },
            volumeProfileColumns: {
                get() {
                    return this.$store.state[this.namespace].volumeProfileColumns;
                },
                set(volumeProfileColumns) {
                    this.$store.commit(this.namespace + '/setVolumeProfileColumns', volumeProfileColumns);
                }
            }
        }
    }
</script>
