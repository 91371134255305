<template>
    <div style="border: 1px solid darkgray; height: 100%; width: 100%; padding: 15px;">

        <a id="downloadAnchorElem" style="display:none"></a>

        <table style="width: 100%;">
            <tr>
                <td style="width: 100%;">
                    database<b-form-select v-model="database" :options="databaseOptions" 
                                   style="border: 1px solid gray; width: 100%;"></b-form-select>
            user<b-form-select v-model="email" :options="emails" 
                               style="border: 1px solid gray; width: 100%;"></b-form-select>

            <div v-if="savedCharts !== null && typeof savedCharts !== 'undefined'">
                <!--  Object.values(savedCharts){{Object.values(savedCharts)}}  -->
                charts<b-form-select :options="Object.values(savedCharts).map(x => decodeURIComponent(x.key.replaceAll('%2E', '.').replaceAll('star', '*' )))" 
                                     v-bind:select-size="Object.values(savedCharts).length" 
                                     style="border: 1px solid gray; width: 100%;"></b-form-select> 
            </div>
            </td>
            </tr>
        </table>
    </div>
</template>

<script>

    import * as fb from '../firebase';

    export default {
        mounted() {
            console.log("saved-charts-admin.vue mounted() starting.");
            this.users = this.firestoreUsers;
        },
        props: ['namespace'],
        data: function () {
            return {
                database: "firestore",
                databaseOptions: [
                    {value: 'firestore', text: 'firestore'}
                ],
                user: null,
                users: [],
                email: "",
                savedCharts: []
            }
        },
        firestore: {
            firestoreUsers: fb.db.collection('users'),
        },
        computed: {
            emails: {
                get() {
                    return this.users.map(user => user.email);
                },
                set(emails) {
                    emails.forEach(email => {
                        console.log("email=", email);
                        this.users.push({email: email});

                    });
                }
            }
        },
        watch: {
            email: {
                // call it upon creation too
                immediate: true,
                handler(email) {
                    console.log("watching email=", email);
                    if (email !== "") {
                        this.$bind('savedCharts', fb.db.collection('saved-charts').doc(email));
                    }
                }
            },
            emails() {
                console.log("watching emails.");
                if (this.emails.length > 0) {
                    this.email = this.emails[0];
                }
            },
        },
        methods: {
            saveChart(generalForm) {
                // let json = JSON.stringify(generalForm, null, 1);
                console.log("generalForm=", generalForm);
                let key = generalForm.saveName.replace(/\./g, 'Period').replace(/\//g, 'ForwardSlash');
                generalForm.key = key;
                console.log("key=", key);
                fb.db.collection('saved-charts')
                        .doc(this.email)
                        .collection('charts')
                        .doc(generalForm.key)
                        .set({generalForm})
                        .then(() => {
                            console.log('updated!');
                        });
            },
            saveCharts() {
                this.savedCharts.forEach(chart => {
                    this.saveChart(chart);
                });
                this.transferLayout();
            },
            transferLayout() {
                let layout = this.savedCharts.map((chart, index) => {
                    return {
                        id: index,
                        name: chart.saveName,
                        isLeaf: true,
                        addLeafNodeDisabled: true
                    }
                });
                console.log("layout", layout);

                fb.db.collection('saved-charts')
                        .doc(this.email)
                        .collection('layout')
                        .doc('layout-document')
                        .set({"layout": layout})
                        .then(() => {
                            console.log('layout saved.')
                        });

            },
            download() {
                console.log("download() starting.");
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.savedCharts));
                var dlAnchorElem = document.getElementById('downloadAnchorElem');
                dlAnchorElem.setAttribute("href", dataStr);
                dlAnchorElem.setAttribute("download", "charts.json");
                dlAnchorElem.click();
            }
        }
    };

</script>

