<template>
    <div  style="border-left: 1px solid gray; padding: 6px">

        <b-button variant="dark" @click="updateState" style="">Update State</b-button> 

        <b-form-textarea
            id="json"
            v-model="json"
            rows="50"
            max-rows="50"
            style=" width: 300px; margin-top: 5px; border: 1px solid gray"
            ></b-form-textarea>

    </div>
</template>

<script>
    export default {
        mounted() {
            console.log("state-editor.vue mounted() starting. this.namespace=", this.namespace);
        },
        props: ["namespace"],
        data() {
            return {
                localState: {}
            };
        },
        computed: {
            json: {
                get() {
                   // console.log("get() json");
                    if (this.namespace !== null) {
                        let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                        delete generalForm.browserSideOnly;
                        return JSON.stringify(generalForm, undefined, 4);
                    } else {
                        return "";
                    }
                },
                set(json) {
                    console.log("set() json");
                    this.localState = JSON.parse(json);
                }
            }
        },
        methods: {
            updateState() {
                console.log("updateState() starting.");
                this.$store.commit(this.namespace + '/setGeneralForm', this.localState);
                this.$store.dispatch(this.namespace + '/getContractListsFromServer');
            }
        }
    };
</script>

