<template>
    <span>
        <b-button id="popover-target-rsi" size="sm" style="color: white"
                  v-bind:style="{backgroundColor: addRSIPanel === true ? 'darkgray' : 'black' }">
            RSI
        </b-button>
        <b-popover target="popover-target-rsi" triggers="click blur" placement="bottom" ref="popover">
           
                <b-container class="container-fluid" style="font-family: verdana; border:1px solid gray; text-align: right; background-color: whitesmoke; width: fit-content; padding-right: 7px ">
                    <b-form-row align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: 1rem">  
                            add RSI panel:
                            <b-form-select style="border:1px solid gray; width: auto;"
                                           v-model="addRSIPanel" :options="options"></b-form-select>

                        </b-col>
                    </b-form-row>

                    <b-form-row v-if="addRSIPanel" align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: 1rem">  
                            period:&nbsp;
                            <b-form-select style="border:1px solid gray; width: auto;"
                                           v-model="rsiPeriod" :options="rsiPeriodOptions"></b-form-select>

                            <span style="font-weight: normal">   &nbsp;days</span>
                        </b-col>
                    </b-form-row>
                </b-container>
                                     
        </b-popover>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                rsiPeriodOptions: Array(14).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ]
            };
        },
        computed: {
            addRSIPanel: {
                get() {
                    return this.$store.state[this.namespace].addRSIPanel;
                },
                set(addRSIPanel) {
                    this.$store.commit(this.namespace + '/setAddRSIPanel', addRSIPanel);
                }
            },
            rsiPeriod: {
                get() {
                    return  this.$store.state[this.namespace].rsiPeriod;
                },
                set(rsiPeriod) {
                    this.$store.commit(this.namespace + '/setRsiPeriod', rsiPeriod);
                }
            }
        }
    }
</script>
