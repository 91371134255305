<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <p style="padding: 4px">
                    Metti qui le istruzioni.
                </p>
            </b-sidebar>
        </div>
        <h2 style="text-align: left;">Trade Maps
        </h2>
        <table v-if="namespace !== 'undefined'" style="text-align: left; margin-bottom: 0px;"> 
            <tr>
                <td style="vertical-align: top;">
                    <table>
                        <tr>
                            <td style="vertical-align: top">  
                        <commodity-box v-bind:namespace="namespace"
                                       v-bind:max-legs="2"
                                       v-bind:showMultSelector="false">
                            <template v-slot:contract-aligner>
                                <contract-aligner-selector v-bind:namespace="namespace"
                                                           v-bind:title="'Aligner/Selector'"
                                                           v-bind:open-only="true"></contract-aligner-selector>     
                            </template>
                        <!--    <template v-slot:data-source>
                                <span style="margin-right: 4px; color: black; font-weight: bold">dataSource:</span><span style="color: black">{{dataSourceDisplay}}</span>
                            </template>  -->
                        </commodity-box>
                </td>
                </tr>
        </table>
        </td>
        <td style="vertical-align: top; text-align: right; height: available; padding: 1px; background-color: white;">

            <table style="color: black; height: 100%; background-color: white;">
                <tr>
                    <td style="vertical-align: bottom; background-color: white">
                <b-row align-h="start" align-v="end" class="row no-gutters" style=" background-color: white; padding-bottom: 2px">
                    <b-col md="auto"> 
                        <b-button-group style="border: 1px solid gray; white-space: nowrap; background-color: #E8E8E8; font-weight: bold; padding-bottom: 2px; padding: 3px 3px 2px 2px"> 
                            <parameter-to-map v-bind:namespace="namespace"></parameter-to-map>
                        </b-button-group>
                    </b-col>
                </b-row>
                <b-row align-h="start" align-v="end" class="row no-gutters" style=" background-color: white; padding-bottom: 0px">
                    <b-col md="auto">  
                        <b-button-group style="border: 1px solid gray; padding: 1px; white-space: nowrap; background-color: #E8E8E8"> 
                            <b-button v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray; margin: 1px;">Help</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
        </td>
                <td style="vertical-align: top; text-align: left; height: available; padding: 1px; background-color: white;">
 <div style="border: 1px solid gray; margin-left: 2px; padding: 3px;;">
                        <div style="white-space: nowrap"><b>mpe</b> = maximum profitable excursion</div>
                        <div style="white-space: nowrap"><b>mae</b> = maximum adverse excursion</div>
                        <div style="white-space: nowrap"><b>appd</b> = average profit per day</div>
                    </div>
                </td>
        </tr>
        </table>

        </td>
        </tr>
        </table>                   
    </div>
</template>

<script>

    import commodityBox from '@/components/commodity-box';
    import contractAlignerSelector from '@/components/contract-aligner-selector';
    import parameterToMap from '@/components/parameter-to-map';

    export default {
        components: {
            commodityBox,
            contractAlignerSelector,
            parameterToMap
        },
        mounted() {
            console.log("trade-maps-input.vue mounted() starting. this.namespace=", this.namespace);
        },
        props: ['namespace'],
        computed: {
            dataSourceDisplay() {
                return this.$store.state[this.namespace].dataSource === "barchart" ? "delayed data" : "end-of-day data";
            }
        }
    };

</script>
