<template>
    <div style="text-align: left; width: fit-content; background-color: white;">
                    
        <h2 style="text-align: left;">Trade Search
        </h2>
        <table border="0" BORDERCOLOR="gray" id="mainTable" width="auto" cellspacing="0"
               cellpadding="1" style="border: 0px solid gray;; color: black;">
            <tr>
                <td style="vertical-align: top; padding: 4px">
                    <div>
                        <b-sidebar id="sidebar-help" title="Help" shadow right>
                            <p style="padding: 4px">
                                Metti qui le istruzioni.
                            </p>
                        </b-sidebar>
                    </div>
                    <span style="">
                        This database contains intracommodity trades with 1 or 2 legs that have an 80% or greater
                        historical probability of profit in the last 15 years. For more information, press

                        <b-button v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray">Help</b-button>.
                    </span> 
                </td>
                <td>
                    <div style="border: 1px solid gray; margin: 3px; padding: 3px;;">
                        <div style="white-space: nowrap"><b>mpe</b> = maximum profitable excursion</div>
                        <div style="white-space: nowrap"><b>mae</b> = maximum adverse excursion</div>
                        <div style="white-space: nowrap"><b>appd</b> = average profit per day</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="vertical-align: top;">
            <b-form inline style="padding-left: 1px;">

                <b-button-group style="border: 1px solid gray; padding: 3px; margin: 1px 5px 0px 0; background-color: #E8E8E8">
                    <span style="padding: 7px 2px 0 5px; font-weight: bold"> legs:</span>
                    <b-form-select v-model="legs" :options="legsOptions" style="border:1px solid gray;"></b-form-select>
                    <b-form-select v-model="position" :options="positionOptions"  style="border:1px solid gray; " ></b-form-select>
                    <b-form-select v-model="source" :options="sourceOptions" style="; border:1px solid gray;"></b-form-select>
                    <b-form-select v-if="source == 'singleCommodity'" v-model="commodity" :options="commodityOptions" style="border:1px solid gray; " ></b-form-select>
                </b-button-group>

                <b-button-group style="border: 1px solid gray; padding: 3px; margin: 1px 5px 0px 0; background-color: #E8E8E8">
                    <span style="padding: 7px 2px 0 5px; font-weight: bold"> sort by:</span>
                    <b-form-select style="border:1px solid gray; margin-right: 10px; color: white; background-color: red; font-weight: bold" v-model="sortParameter" :options="sortParameterOptions"></b-form-select>
                    <span style="padding: 7px 2px 0 0; font-weight: bold">direction:</span>
                    <b-form-select style="border:1px solid gray;" v-model="direction" :options="directionOptions"></b-form-select>
                </b-button-group>

                <b-button-group style="border: 1px solid gray; padding: 3px; margin: 1px 5px 0px 0; background-color: #E8E8E8">
                    <b-input v-model.lazy.number="minDays" style="border: 1px solid gray; width: 50px;  text-align: right;"> </b-input>
                    <span style="padding: 7px 2px 0 5px; font-weight: bold">&le;&nbsp;days&nbsp;&le;&nbsp;</span>
                    <b-input  v-model.lazy.number="maxDays" style="border: 1px solid gray; width: 50px; text-align: right;"> </b-input>
                </b-button-group>

                <b-button-group style="border: 1px solid gray; padding: 3px; margin: 1px 5px 0px 0; background-color: #E8E8E8">
                    <span style="padding: 7px 2px 0 5px; font-weight: bold">tab title:</span>
                    <b-input v-model.lazy.number="tabTitle" style="border: 1px solid gray; width: auto; text-align: left"> </b-input>
                </b-button-group>
            </b-form>
            </td>
            </tr>

            <tr>
                <td colspan="2">   
            <b-container fluid style="width: auto; margin-bottom: 2px; margin-top: 2px">
                <b-row align-h="start" style=" background-color: white; padding-bottom: 4px">
                    <b-col md="auto" v-for="n in userConditions.length" v-bind:key="n" style="padding:0px;">  
                        <user-condition v-bind:namespace="namespace" 
                                        v-bind:index="n-1"></user-condition>
                    </b-col>
                </b-row>
            </b-container>
            </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import {getCommoditiesObject} from '../../../js/main';
    import userCondition from '@/components/user-condition';

    export default {
        components: {
            userCondition
        },
        mounted() {
            console.log("search-input.vue mounted() starting. this.namespace=", this.namespace);
            this.makeQuery();
        },
        props: ['namespace'],
        data: function () {
            return {
                legsOptions: [
                    {value: 1, text: '1'},
                    {value: 2, text: '2'},
                    {value: '1 or 2', text: '1 or 2'}
                ],
                sourceOptions: [
                    {value: 'singleCommodity', text: 'intra-commodity'},
                    {value: 'all', text: 'all commodities'}
                ],
                positionOptions: [
                    {value: 'long', text: 'long'},
                    {value: 'short', text: 'short'},
                    {value: 'either', text: 'long or short'}
                ],
                sortParameterOptions: [
                    {value: 'appd', text: 'appd'},
                    {value: 'rrr', text: 'avg mpe/avg mae '},
                    {value: 'avgMin', text: 'avg mae'},
                    {value: 'worstMin', text: 'worst mae'},
                    {value: 'avgMax', text: 'avg mpe'},
                    {value: 'bestMax', text: 'best mpe'},
                    {value: 'open', text: 'open'}
                ],
                directionOptions: [
                    {value: 'desc', text: 'down'},
                    {value: 'asc', text: 'up'}
                ]
            }
        },
        computed: {
            tabTitle: {
                get() {
                    return this.$store.state[this.namespace].tabTitle;
                },
                set(tabTitle) {
                    this.$store.commit(this.namespace + '/setTabTitle', tabTitle);
                }
            },
            rowsPerPage: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.rowsPerPage;
                },
                set(rowsPerPage) {
                    this.$store.commit(this.namespace + '/chartParameters/setRowsPerPage', rowsPerPage);
                    this.makeQuery();
                }
            },
            page: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.page;
                },
                set(page) {
                    console.log("page=", page);
                    // this.$store.commit(this.namespace + '/chartParameters/setPage', page);
                    // this.makeQuery();
                }
            },
            commodityOptions() {
                console.log("getCommoditiesObject()=", getCommoditiesObject());
                return Object.values(getCommoditiesObject()).map(x => {
                    return {value: x.symbol, text: x.name};
                });
            },
            commodity: {
                get() {
                    let commodity = this.$store.getters[this.namespace + '/c'][0];
                    return  commodity;
                },
                set(commodity) {
                    this.$store.dispatch(this.namespace + '/changeCommodityLeg', {commodity: commodity, index: 0});
                    this.makeQuery();
                }
            },
            legs: {
                get() {
                    return this.$store.state[this.namespace].search.legs;
                },
                set(legs) {
                    this.$store.commit(this.namespace + '/search/setLegs', legs);
                    this.makeQuery();
                }
            },
            sortParameter: {
                get() {
                    return this.$store.state[this.namespace].search.sortParameter;
                },
                set(sortParameter) {
                    this.$store.commit(this.namespace + '/search/setSortParameter', sortParameter);
                    this.makeQuery();
                }
            },
            position: {
                get() {
                    return this.$store.state[this.namespace].search.position;
                },
                set(position) {
                    this.$store.commit(this.namespace + '/search/setPosition', position);
                    this.makeQuery();
                }
            },
            direction: {
                get() {
                    return this.$store.state[this.namespace].search.direction;
                },
                set(direction) {
                    this.$store.commit(this.namespace + '/search/setDirection', direction);
                    this.makeQuery();
                }
            },
            source: {
                get() {
                    return this.$store.state[this.namespace].search.source;
                },
                set(source) {
                    this.$store.commit(this.namespace + '/search/setSource', source);
                    this.makeQuery();
                }
            },
            minDays: {
                get() {
                    return this.$store.state[this.namespace].search.minDays;
                },
                set(minDays) {
                    this.$store.commit(this.namespace + '/search/setMinDays', minDays);
                    this.makeQuery();
                }
            },
            maxDays: {
                get() {
                    return this.$store.state[this.namespace].search.maxDays;
                },
                set(maxDays) {
                    this.$store.commit(this.namespace + '/search/setMaxDays', maxDays);
                    this.makeQuery();
                }
            },
            userConditions: {
                get() {
                    return this.$store.state[this.namespace].search.userConditions;
                }
            }
        },
        watch: {
            userConditions(userConditions) {
                console.log("watch: userConditions=", userConditions);
                this.makeQuery();
            },
            page: function (page) {
                console.log("watch: page=", page);
                this.makeQuery();
            },
            rowsPerPage: function (rowsPerPage) {
                console.log("watch: rowsPerPage=", rowsPerPage);
                this.makeQuery();
            }
        },
        methods: {
            makeQuery(resetPage = true) {
                console.log("makeQuery() starting");
                // console.trace();
                //let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // console.log("generalForm=", generalForm);

                //  console.log("minDays=", this.minDays);
                //  console.log("typeof minDays=", typeof this.minDays);
                if (this.minDays === '' || this.maxDays === '') {
                    return;
                }
                //updateState(generalForm);

                this.trades = []
                if (resetPage) {
                    this.page = 1;
                }

                var tradeLengthConditions = " DATEDIFF(close, open ) >= " + this.minDays + " and DATEDIFF(close, open ) <= " + this.maxDays;
                var legsCondition = "";
                if (this.legs === 1) {
                    // eslint-disable-next-line
                    legsCondition = " and ticker NOT LIKE '%\:%' ";
                } else if (this.legs === 2) {
                    // eslint-disable-next-line
                    legsCondition = " and ticker LIKE '%\:%' ";
                }

                var sourceCondition = "";
                if (this.source == "singleCommodity") {
                    sourceCondition = " and ticker like '" + this.commodity + "-%'";
                } /*else if (this.legs == "group") {
                 
                 }*/

                var positionCondition = "";
                if (this.position !== "either") {
                    positionCondition = positionCondition = " and position like '%" + this.position + "%'";
                }

                var userCondition = "";
                console.log("this.userConditions=", this.userConditions);
                console.log("this.userConditions.length=", this.userConditions.length);
                if (this.userConditions.length > 0) {
                    for (var i = 0; i < this.userConditions.length; i++) {
                        userCondition = userCondition + " and " + this.userConditions[i].parameter + " " + this.userConditions[i].comparator + " " + this.userConditions[i].value;
                    }
                }
                // console.log("userCondition=", userCondition);

                //  var groupByCondition = " GROUP BY ticker ";

                var aggregator = "max";
                if (this.direction === "asc") {
                    aggregator = "min";
                }

                console.log("this.page=", this.page, " this.rowsPerPage=", this.rowsPerPage);

                var query = `select SQL_CALC_FOUND_ROWS trade.*
                FROM data.trades AS trade
                INNER JOIN
                   (
                                SELECT ticker, ` + aggregator + `(` + this.sortParameter + `) AS ` + this.sortParameter + `
                                FROM data.trades
                                where ` + tradeLengthConditions + legsCondition + sourceCondition + positionCondition + userCondition +
                        ` GROUP BY ticker
                    ) AS trade2
                ON trade.ticker = trade2.ticker AND trade.` + this.sortParameter + ` = trade2.` + this.sortParameter +
                        ` HAVING ` + tradeLengthConditions + legsCondition + sourceCondition + positionCondition + userCondition +
                        ` ORDER BY ` + this.sortParameter + ` ` + this.direction +
                        ` LIMIT ` + (this.page - 1) * this.rowsPerPage + ` , ` + this.rowsPerPage + `;`;

                console.log("query=", query);
                this.$store.commit(this.namespace + '/search/setQuery', query);
            }
        }
    };

</script>
