<template>
    <div style="margin: 0px; width: 100%;">

        <div v-bind:id="namespace+'-error'"></div> 
        <calculator-chart v-bind:ref="namespace" v-bind:namespace="namespace"  
                          v-bind:div-name="namespace+'-calculatorChartDiv'" 
                          v-bind:parsedData="tradeChartParsedData"
                          v-bind:height="height"
                          style="border: 0px solid gray;"></calculator-chart>  

        <div v-bind:id="namespace+'-tradeTableDiv'" >
            <trade-table v-bind:namespace="namespace" v-bind:parsedData="tradeTableParsedData" style="border-left: 0px solid gray;
                         border-right: 0px solid gray; border-bottom: 0px solid gray;"></trade-table>

        </div>

    </div>
</template>

<script>
    import tradeTable from '@/components/trade-table';
    import moment from 'moment';
    import $ from "jquery";
    import calculatorChart from '@/components/am4charts-calculator-chart';
    import {spreadTitle} from "@/js/main";

    export default {
        components: {
            tradeTable, calculatorChart
        },
        mounted() {
            console.log("calculator-output.vue mounted() starting. this.ticker=", this.ticker);
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            if (generalForm.program === "Calculator") {
                this.$store.commit(this.namespace + '/setTabTitle', "Calc: " + this.ticker);
            }
        },
        props: {
            namespace: {
                type: String,
                default: ""
            },
            height: {
                type: String,
                default: "200px"
            }
        },
        data: function () {
            return{
                tradeTableParsedData: null,
                tradeChartParsedData: null,
                initialized: false,
                title: null
            }
        },
        computed: {
            open() {
                return this.$store.state[this.namespace].open;
            },
            close() {
                return this.$store.state[this.namespace].close;
            },
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            y1() {
                return this.$store.state[this.namespace].y1;
            }
        },
        watch: {
            open: function (open) {
                console.log("watch: open=", open);
                let openDateObj = moment(open, "YYYY-MM-DD").add(12, 'hours');
                let closeDateObj = moment(this.$store.state[this.namespace].close, "YYYY-MM-DD").add(12, 'hours');

                if (openDateObj.isBefore(closeDateObj)) {
                    this.getData();
                } else {
                    let obj = JSON.parse(JSON.stringify(this.tradeTableParsedData));
                    obj.values = {};
                    this.tradeTableParsedData = obj;

                    let obj2 = JSON.parse(JSON.stringify(this.tradeChartParsedData));
                    this.tradeChartParsedData = obj2;
                }
            },
            close: function (close) {
                console.log("watch: close=", close);
                let openDateObj = moment(this.$store.state[this.namespace].open, "YYYY-MM-DD").add(12, 'hours');
                let closeDateObj = moment(close, "YYYY-MM-DD").add(12, 'hours');

                if (openDateObj.isBefore(closeDateObj)) {
                    this.getData();
                } else {
                    let obj = JSON.parse(JSON.stringify(this.tradeTableParsedData));
                    obj.values = {};
                    this.tradeTableParsedData = obj;

                    let obj2 = JSON.parse(JSON.stringify(this.tradeChartParsedData));
                    this.tradeChartParsedData = obj2;
                }
            },
            ticker: function (newTicker, oldTicker) {
                console.log("watch: newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                console.log("generalForm=", generalForm);
                if (newTicker !== null && newTicker !== oldTicker && typeof this.$refs[this.namespace].$options.chart.titles !== 'undefined') {
                   // console.log("title=", this.$refs[this.namespace].$options.chart.titles.values[0].text);
                    this.$refs[this.namespace].$options.chart.titles.values[0].text = newTicker + " . . .  ";

                    this.getData();
                    if (generalForm.program === "Calculator") {
                        this.$store.commit(this.namespace + '/setTabTitle', "Calc: " + this.ticker);
                    }
                }
            },
            activeModuleName: function (activeModuleName) {
                console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                    this.getData();
                }
            },
            y1(y1) {
                console.log("watch: y1=", y1);
                this.getData();
            }
        },
        methods: {
            getData() {
                console.log("getData() starting.");
                this.$store.commit(this.namespace + '/setLoading', true);
                // console.log("this.$refs=", this.$refs);

                this.tradeTableParsedData = {};
                this.$refs[this.namespace].removeChart();
              //  $('#' + this.namespace + '-tradeTableDiv').css('visibility', 'hidden');

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.trades = [];

                generalForm.study = "calculator";
                generalForm.generator = "SeasonalsGenerator";

                console.log("this.open=", this.open, " this.close=", this.close);
                if(this.open === null ){
                    let currentDate = moment();
                    this.$store.commit(this.namespace + '/setOpen', currentDate.format("YYYY-MM-DD"));
                }
                if(this.close === null){
                    let close = moment().add(1, "month");
                    this.$store.commit(this.namespace + '/setClose', close.format("YYYY-MM-DD"));
                }

                generalForm.openDate = this.$store.getters[this.namespace + "/openDate"];
                generalForm.openMonth = this.$store.getters[this.namespace + "/openMonth"];
                generalForm.closeDate = this.$store.getters[this.namespace + "/closeDate"];
                generalForm.closeMonth = this.$store.getters[this.namespace + "/closeMonth"];

                delete generalForm.browserSideOnly;
                let json = JSON.stringify(generalForm, null, 2);
                let url = this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/ControllerServlet2";
                let that = this;
                $.ajax({
                    url: url,
                    type: "POST",
                    data: {"json": json},
                    success: function (data) {
                      //  $("#" + that.namespace + '-tradeTableDiv').css('visibility', 'visible');
                        // console.log("data=", data);
                        that.$store.commit(that.namespace + '/setLoading', false);
                        let parsedData = JSON.parse(data);
                        that.tradeTableParsedData = parsedData;
                        console.log("parsedData=", parsedData);

                        if (typeof parsedData.chartJson !== 'undefined' && parsedData.chartJson !== null) {
                            let temp = parsedData.chartJson.reverse().map(x => {
                                x.open = moment(x.open, "YYYYMMDD").format("YYYY-MM-DD");
                                x.close = moment(x.close, "YYYYMMDD").format("YYYY-MM-DD");
                                // console.log("x=", {...x});
                                return x;
                            });
                            console.log("temp=", JSON.parse(JSON.stringify(temp)));
                            let latestDate = temp.reduce((prev, curr) => prev.close > curr.close ? prev : curr);
                            console.log("latestDate=", latestDate);

                            let i = 0;
                            while (moment(generalForm.close).isAfter(moment(latestDate.close))) {
                                console.log("Shifting dates.");
                                temp.forEach(x => {
                                    x.open = moment(x.open).add(1, 'years').format("YYYY-MM-DD");
                                    x.close = moment(x.close).add(1, 'years').format("YYYY-MM-DD");
                                });
                                console.log("temp=", JSON.parse(JSON.stringify(temp)));
                                latestDate = temp.reduce((prev, curr) => prev.close > curr.close ? prev : curr);
                                console.log("latestDate.close=", latestDate.close);
                                i++;
                                if (i > 4)
                                    break;
                            }

                            temp.forEach(x => {
                                // console.log("x=", x);
                                generalForm.selected = [x.selected];
                                // console.log("generalForm=", generalForm);
                                let invertDatesMultiplier = 1;
                                let noLegSwapping = false;
                                let ticker = spreadTitle(generalForm, invertDatesMultiplier, noLegSwapping);
                               // console.log("ticker=", ticker);

                                x.tooltip = ticker;
                            });
                            console.log("temp=", temp);

                            that.tradeChartParsedData = temp;
                        } else {
                            that.tradeChartParsedData = [];
                        }
                    },
                    fail: function (data) {
                        console.log("ajax call failed.");
                        $("#progress").hide();
                        $("#button").show();
                        console.log(data);
                    }
                });
            }
        }
    };

</script>
