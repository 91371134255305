let searchInitialState = {
    "sampleContract": ["CT2020H", "C2020K", "C2020N", "C2020Z"],
    user: "A4A-user0",
    password: "M1-K8xQgmR",
    intracommodity: false,
    program: "Search",
    tabTitle: "Search Title",
    chartParameters: {
        "page": 1,
        chartsPerRow: 2,
        rowsPerPage: 20,
        chartsPerPage: 4,
        balloons: true
    },
    "legs": 1,
    search: {
        sortParameter: "rrr",
        legs: "1 or 2",
        position: "either",
        direction: "desc",
        source: "singleCommodity",
        minDays: 2,
        maxDays: 100,
        userConditions: [
            {
                parameter: "appd",
                comparator: ">",
                value: 5
            }
        ],
        query: "select SQL_CALC_FOUND_ROWS trade.*\n\
                FROM data.trades AS trade\n\
                            where  DATEDIFF(close, open ) >= 2 and DATEDIFF(close, open ) <= 100 and appd > 5 GROUP BY ticker) AS trade2\n\
            ON trade.ticker = trade2.ticker AND trade.rrr = trade2.rrr HAVING  DATEDIFF(close, open ) >= 2 and DATEDIFF(close, open ) <= 100 and appd > 5 ORDER BY rrr desc LIMIT 0 , 20;"
    }
};

export {searchInitialState};