<template>
    <span style="color: black">
        <b-form inline style="width: auto; margin: 0 3px 0 0; border-left: 1px solid gray; border-top: 1px solid gray; border-right: 1px solid gray; padding: 3px; background-color: whitesmoke; white-space: nowrap">
            <span style="margin: 0 4px 0 10px; font-weight: bold">open:</span>
            <b-form-datepicker v-model="open" :max="maximumOpen" size="sm" style="border: 1px solid gray;"></b-form-datepicker>
        </b-form>
        <b-form inline style="margin: 0 3px 0 0; border-left: 1px solid gray; border-bottom: 1px solid gray; border-right: 1px solid gray; padding: 3px; background-color: whitesmoke">
            <span style="margin: 0 4px 0 10px; font-weight: bold">close:</span>
            <b-form-datepicker v-model="close" :min="minimumClose" :max="maximumClose" size="sm" style="border: 1px solid gray;"></b-form-datepicker>
        </b-form>

    </span>
</template>

<script>

    import moment from 'moment';

    export default {
        mounted() {
           // console.log("open-close-selector.vue starting. this.namespace=", this.namespace);
        },
        props: ['namespace'],
        computed: {
            maximumOpen() {
                let maximumOpen = moment().add(364, 'days').format('YYYY-MM-DD');
               // console.log("maximumOpen=", maximumOpen);
                return maximumOpen;
            },
            minimumClose() {
                let minimumClose = moment(this.open).add(1, 'days').format('YYYY-MM-DD');
               // console.log("minimumClose=", minimumClose);
                return minimumClose;
            },
            maximumClose() {
                let maximumClose = moment(this.$store.state[this.namespace].open).add(364, 'days').format('YYYY-MM-DD');
               // console.log("maximumClose=", maximumClose);
                return maximumClose;
            },
            open: {
                get() {
                    // let open = moment(this.$store.state[this.namespace].open);
                    return this.$store.state[this.namespace].open;
                },
                set(openString) {
                    console.log("openString=", openString);
                    let open = moment(openString);
                    this.$store.commit(this.namespace + '/setOpen', openString);
                    this.$store.commit(this.namespace + '/setOpenMonth', open.format("MMMM"));
                    this.$store.commit(this.namespace + '/setOpenDate', open.format("DD"));
                }
            },
            close: {
                get() {
                    let close = moment(this.$store.state[this.namespace].close);
                    // console.log("close=", close);
                    return close.format('YYYY-MM-DD');
                },
                set(closeString) {
                    let close = moment(closeString);

                    this.$store.commit(this.namespace + '/setClose', closeString);
                    this.$store.commit(this.namespace + '/setCloseMonth', close.format("MMMM"));
                    this.$store.commit(this.namespace + '/setCloseDate', close.format("DD"));
                }
            }
        }
    }
</script>
