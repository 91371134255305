<template>
    <table style="width: 100%; display: table; table-layout: fixed;">
        <tr>
            <td style="vertical-align: top; width: 50%; border-right: 1px solid darkgray;">
          <multiple-line-chart v-bind:namespace="namespace" 
                             v-bind:div-name="namespace+'-chartDiv'"
                             v-bind:parsedData="parsedData"></multiple-line-chart>  
        </td>

        <td style="vertical-align: top; width: 50%;">
         <plotly-ribbon-chart v-bind:namespace="namespace"
                              v-bind:parsedData="plotlyParsedData"></plotly-ribbon-chart> 
        </td>
        </tr>
    </table> 
</template>

<script>

    import $ from "jquery";
    import {spreadDigits, decode} from "../../../js/main";
    import multipleLineChart from '@/components/am4charts-multiple-line-chart';
    import plotlyRibbonChart from '@/components/plotly-ribbon-chart';

    export default {
        components: {
           multipleLineChart, plotlyRibbonChart
        },
        mounted() {
            console.log("open-spreads-output.vue mounted() starting.");
            if (this.activeModuleName === this.namespace && !this.initialized) {
                this.initialized = true;
                this.getData();
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                parsedData: null,
                plotlyParsedData: null,
                initialized: false,
                data: [],
                layout: {},
                modeBarButtonsToRemove: ['sendDataToCloud', 'orbitRotation', 'pan3d', 'hoverClosest3d']
            }
        },
        computed: {
            start() {
                console.log("this.$store.state[this.namespace].chartParameters.start=", this.$store.state[this.namespace].chartParameters.start);
                return this.$store.state[this.namespace].chartParameters.start;
            },
            end() {
                return this.$store.state[this.namespace].chartParameters.end;
            },
            selected() {
                return this.$store.state[this.namespace].selected;
            },
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            numberOfContractsApart() {
                return this.$store.state[this.namespace].numberOfContractsApart;
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            }
        },
        watch: {
            start: function (start) {
                console.log("watch start=", start);
                let end = this.$store.state[this.namespace].chartParameters.end;

                let newParsedData = JSON.parse(JSON.stringify(this.parsedData));
                newParsedData.values = newParsedData.values.filter(x => x[0] > start && x[0] < end);
                this.plotlyParsedData = newParsedData;
            },
            end: function (end) {
                console.log("watch end=", end);
                let start = this.$store.state[this.namespace].chartParameters.start;

                let newParsedData = JSON.parse(JSON.stringify(this.parsedData));
                newParsedData.values = newParsedData.values.filter(x => x[0] > start && x[0] < end);
                this.plotlyParsedData = newParsedData;
            },
            ticker: function (newTicker, oldTicker) {
                console.log("watch: newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);
                this.getData();
            },
            activeModuleName: function (activeModuleName) {
                console.log("watch activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                    this.getData();
                }
            },
            numberOfContractsApart: function (numberOfContractsApart) {
                console.log("watch numberOfContractsApart=", numberOfContractsApart);
                this.getData();
            }
        },
        methods: {
            getData() {
                console.log("getData() starting.");
                this.$store.commit(this.namespace + '/setLoading', true);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                generalForm.program = "OpenSpreads";
                generalForm.study = "open";
                generalForm.chartType = "amcharts";

                generalForm.selected = [generalForm.sampleContract[0]];
                let digits = spreadDigits(generalForm.selected[0]);
                console.log("digits=", digits);

                delete generalForm.browserSideOnly;
                delete generalForm.playback;
                console.log("generalForm=", generalForm);

                var that = this;
                let json = JSON.stringify(generalForm, null, 2);
                let url = this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/ControllerServlet2";
                console.log("url=", url);
                $.ajax({
                    url: url,
                    type: "POST",
                    data: {"json": json},
                    success: function (data) {
                        that.$store.commit(that.namespace + '/setLoading', false);
                        // console.log("data=", data);
                        let parsedData = JSON.parse(data);
                        console.log("parsedData.dataset=", parsedData.dataset);
                        that.$store.commit(that.namespace + '/setTabTitle', "open: " + decode(parsedData.dataset[0].name) + "...");

                        parsedData.values.forEach(x => {
                            if (x[1] !== null) {
                                x[1] = x[1].toFixed(digits);
                            }
                        });
                        console.log("parsedData=", JSON.parse(JSON.stringify(parsedData)));
                        parsedData.eye = that.$store.state[that.namespace].chartParameters.eye;

                        that.parsedData = JSON.parse(JSON.stringify(parsedData));
                        that.plotlyParsedData = JSON.parse(JSON.stringify(parsedData));
                    },
                    fail: function (data) {
                        console.log("ajax call failed.");
                        that.$store.commit(that.namespace + '/setLoading', false);
                        $("#progress").hide();
                        $("#button").show();
                        console.log(data);
                    }
                });
            }
        }
    };

</script>
