<template>
    <table border="0" style="border: 0px solid darkgray; font-weight: normal;">
        <tr>
            <td style="vertical-align: top; margin: 5px;">

        <plotly-heatmap v-bind:namespace="namespace"
                        v-bind:direction="direction" 
                        v-bind:parameterToMap="parameterToMap"></plotly-heatmap>
        </td>
        <td style="width: 400px; vertical-align: top; padding-bottom: 4px">

            <div style="margin: 3px 3px 3px 0px; border: 1px solid darkgray;">
                <basic-chart-output v-bind:namespace="namespace"></basic-chart-output>  
            </div>
            <div style="margin: 0 3px 0px 0px; border: 1px solid darkgray;">
                <calculator-output v-bind:namespace="namespace"
                                   v-bind:height="'155px'"
                                   v-bind:width="'800px'"></calculator-output>  
            </div>  

        </td>
        </tr>
    </table>
</template>

<script>
    import moment from 'moment';
    import $ from "jquery";
    import basicChartOutput from '@/components/programs/BasicChart/basic-chart-output';
    import calculatorOutput from '@/components/programs/Calculator/calculator-output';
    import plotlyHeatmap from '@/components/plotly-heatmap';
    import {orderContracts, contractNameDecomposer} from '../../../js/main';
    import {decompress} from "../../../js/jsonhNew";

    export default {
        components: {
            calculatorOutput, basicChartOutput, plotlyHeatmap
        },
        mounted() {
            console.log("trade-maps-output.vue mounted() starting. this.mapTabTitle=", this.mapTabTitle);
            this.$store.commit(this.namespace + '/setTabTitle', "Map: " + this.mapTabTitle);

            if (this.activeModuleName === this.namespace && !this.initialized) {
                this.initialized = true;
                this.getData();
                this.getMapData();
            }
        },
        props: ['namespace'],
        beforeDestroy() {
            console.log("trade-maps-output.vue beforeDestroy() starting.");
            // removeHeatmap(this);
        },
        data: function () {
            return{
                tradeTableParsedData: null,
                tradeChartParsedData: null,
                initialized: false,
                direction: null
            }
        },
        computed: {
            open() {
                return this.$store.state[this.namespace].open;
            },
            close() {
                return this.$store.state[this.namespace].close;
            },
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            mapTabTitle() {
                let title;
                let options = this.$store.getters[this.namespace + '/listOptions'];
                console.log("options=", options);
                let openOptions = options.filter(x => x.open === true);
                if (openOptions.length > 0) {
                    title = openOptions[openOptions.length - 1].value;
                } else {
                    title = options[0].value;
                }
                console.log("title=", title);
                return title;
            },
            parameterToMap() {
                return this.$store.state[this.namespace].chartParameters.parameterToMap;
            }
        },
        watch: {
            ticker: function (newTicker, oldTicker) {
                console.log("watch: newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);
                if (newTicker !== null && newTicker !== oldTicker) {
                    this.$store.commit(this.namespace + '/setTabTitle', "Map: " + this.mapTabTitle);
                    //removeStatChart();
                    this.getData();
                    this.getMapData();
                }
            },
            activeModuleName: function (activeModuleName) {
                console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                    this.getData();
                    this.getMapData();
                }
            },
            parameterToMap() {
                console.log("watching parameterToMap");
                if (this.activeModuleName === this.namespace) {
                    this.getMapData();
                }
            }
        },
        methods: {
            getMapData() {
                console.log("getMapData() starting.");
                this.$children[0].removeHeatmap();

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                let contract = generalForm.sampleContract.slice(0, generalForm.legs);
                if (generalForm.legs == 1 || contract[0] !== contract[1]) {
                    let isBefore = true;
                    if (generalForm.legs > 1) {
                        let newOrder = orderContracts(contract[0], contract[1])
                        isBefore = (contract[0] == newOrder[0]);
                    }
// console.log("isBefore=" + isBefore);
                    if (!isBefore) {
                        contract = contract.reverse();
                    }
                    console.log("contract=", contract);
                    this.direction = "up";
                    if ((generalForm.legs == 1 && generalForm.p[0] == -1) || (generalForm.legs == 2 && generalForm.p[0] == 1 && !isBefore) || (generalForm.legs == 2 && generalForm.p[0] == -1 && isBefore)) {
                        this.direction = "down";
                    }

                    let key = "";
                    let cND;
                    for (let i = 0; i < generalForm.legs; i++) {
                        cND = contractNameDecomposer(contract[i]);
                        key = key + cND.commoditySymbol + "-" + cND.monthSymbol + ":";
                    }
                    key = key.substring(0, key.length - 1);
                    let S3key = cND.commoditySymbol + "/" + key;
                    console.log("S3key=" + S3key);
                    console.log("this.direction=" + this.direction);

                    let that = this;
                    //  let url = "https://trade-maps-2.s3.eu-central-1.amazonaws.com/" + S3key;
                    let url = "https://d3pjkx7t41xwxl.cloudfront.net/" + S3key;
                    // let url = "https://trade-maps-new.s3.amazonaws.com/" + S3key;
                    //  let url = "https://trade-maps-2.s3.amazonaws.com/" + S3key;
                    $.ajax({
                        url: url,
                        type: "GET",
                        success: function (data) {
                            //  console.log("data=", data);
                            generalForm.title = "title"; //S3key;
                            try {
                                if (data !== "[]") {
                                    let vectorMap = decompress(data);
                                    console.log("vectorMap=", vectorMap);
                                    // console.log("that.$children[0]=", that.$children[0]);
                                    that.$children[0].runPlotly(vectorMap, that.direction, that.parameterToMap);
                                } else {
                                    console.log("No open spreads.");

                                    $('#' + that.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin-left:10px'>No open " +
                                            (generalForm.legs == 1 ? "contracts" : "spreads") + ".</H3>");
                                }
                            } catch (error) {
                                console.log("error=", error);

                                // $('#chartdiv').html("<H4 style='color: red; text-align: left; margin-left:10px'>Not enough data.</H3>");
                                //  updateOtherCharts();

                            }
                        },
                        error: function () {
                            //  $('#plotlyHeatMapDiv').html("<H3 style='color: red; text-align: left; margin-left:10px'>Error.</H3>");
                            $('#' + that.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin-left:10px'>No open " +
                                    (generalForm.legs == 1 ? "contracts" : "spreads") + ".</H3>");
                        }
                    });
                } else {
                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("<div style='white-space: nowrap; color: red; font-size: 14px; padding:5px; font-weight: normal'>" +
                            "Nothing to map." + "</div>");
                }
            },
            getData() {
                console.log("getData() starting.");
                this.$store.commit(this.namespace + '/setLoading', true);

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.trades = [];
                this.numberOfTrades = 0;
                this.upNumber = 0;
                this.percentUp = 0;
                this.avgChange = 0;
                this.avgEquityChange = 0;
                this.avgMin = 0;
                this.avgMax = 0;

                generalForm.study = "calculator";
                generalForm.generator = "SeasonalsGenerator";

                generalForm.openDate = this.$store.getters[this.namespace + "/openDate"];
                generalForm.openMonth = this.$store.getters[this.namespace + "/openMonth"];
                generalForm.closeDate = this.$store.getters[this.namespace + "/closeDate"];
                generalForm.closeMonth = this.$store.getters[this.namespace + "/closeMonth"];

                delete generalForm.browserSideOnly;
                let json = JSON.stringify(generalForm, null, 2);
                let url = this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/ControllerServlet2";
                let that = this;
                $.ajax({
                    url: url,
                    type: "POST",
                    data: {"json": json},
                    success: function (data) {
                        that.$store.commit(that.namespace + '/setLoading', false);
                        that.tradeTableParsedData = JSON.parse(data);
                        let temp = JSON.parse(data).chartJson.reverse().map(x => {
                            x.open = moment(x.open, "YYYYMMDD").format("YYYY-MM-DD");
                            x.close = moment(x.close, "YYYYMMDD").format("YYYY-MM-DD");
                            // console.log("x=", {...x});
                            return x;
                        });
                        // console.log("chart.data=", JSON.parse(JSON.stringify(chart.data)));
                        console.log("temp=", JSON.parse(JSON.stringify(temp)));
                        let latestDate = temp.reduce((prev, curr) => prev.close > curr.close ? prev : curr);
                        console.log("latestDate=", latestDate);

                        let i = 0;
                        while (moment(generalForm.close).isAfter(moment(latestDate.close))) {
                            console.log("Shifting dates.");
                            temp.forEach(x => {
                                x.open = moment(x.open).add(1, 'years').format("YYYY-MM-DD");
                                x.close = moment(x.close).add(1, 'years').format("YYYY-MM-DD");
                            });
                            console.log("temp=", JSON.parse(JSON.stringify(temp)));
                            latestDate = temp.reduce((prev, curr) => prev.close > curr.close ? prev : curr);
                            console.log("latestDate.close=", latestDate.close);
                            i++;
                            if (i > 4)
                                break;
                        }
                        console.log("temp=", temp);

                        that.tradeChartParsedData = temp;
                    },
                    fail: function (data) {
                        console.log("ajax call failed.");
                        $("#progress").hide();
                        $("#button").show();
                        console.log(data);
                    }
                });
            }
        }
    };

</script>
