<template>
    <div>

        <div style="color: red" v-if="$store.state[this.namespace].selected.length == 0">
            Please select.
        </div>
        <div class="selectDiv">       

            <select v-model="selected" 
                    v-bind:size="listOptions.length" 
                    v-bind:multiple="multiple" class="list">
                <option style="text-align: center"
                        v-for="(option, i) in listOptions" :key="i" 
                        v-bind:value="option.value">
                    {{option.text}}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import {spreadTitle} from "../js/main";

    export default {
        mounted: function () {
           // console.log("contract-selector.vue mounted() starting.");
        },
        props: ['namespace'],
        computed: {
            listOptions() {
              //  console.log("listOptions() starting.")
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
              //  console.log("generalForm=", generalForm);

                return this.$store.getters[this.namespace + '/listOptions'].map(x => {
                    // console.log("x=", x);
                    generalForm.selected = [x.value];
                    let invertDatesMultiplier = 1;
                    let noLegSwapping = true;
                    let ticker = spreadTitle(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping);
                    // console.log("ticker=", ticker);

                    return {value: x.value, text: ticker};
                });
            },
            selected: {
                get() {
                    return this.$store.state[this.namespace].selected;
                },
                set(selected) {
                   // console.log("selected set() starting.");
                    this.$store.commit(this.namespace + '/setSelected', selected);
                }
            },
            multiple() {
                return (this.$store.state[this.namespace].study == "candlesticks") ? false : true;
            }
        }
    }
</script>

<style>
    .selectDiv {
        display:inline-block;
        vertical-align:top; overflow:hidden;
        border:solid grey 0px; 
        background-color: white;
        margin: -3px 2px 4px -1px;
    }

    .list {
        padding: 9px;
        margin:-4px -26px -11px -5px;
        border:solid grey 0px; 
        background-color: white;
    }

    .selectTitle{
        margin:3px 2px -4px 2px;
        font-weight: bold;
        font-size: 12px;
        height: 100%;
        background-color: white
    }

    .contractAligner{
        margin: 0px 0px 3px 0px;
    }
</style>
