<template>
    <span>
        <b-button id="popover-target-sma" size="sm" style="color: white"
                  v-bind:style="{backgroundColor: addSMA === true ? 'darkgray' : 'black' }">
            SMA
        </b-button>
        <b-popover target="popover-target-sma" triggers="click blur" placement="bottom" ref="popover">

            <b-container class="container-fluid" style="border:1px solid gray; text-align: right; background-color: whitesmoke; width: fit-content; padding-right: 7px ">
                <b-form-row align-h="start" style="font-family: verdana; border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: 1rem">  
                        add simple moving average:
                        <b-form-select style="border:1px solid gray; width: auto;"
                                       v-model="addSMA" :options="options"></b-form-select>

                    </b-col>
                </b-form-row>

                <b-form-row v-if="addSMA" align-h="start" style="border:0px solid gray;  padding: 2px">
                        <b-col style="font-weight: bold; font-size: 1rem">  
                        period:&nbsp;
                        <b-form-select style="border:1px solid gray; width: auto; font-size: 12px"
                                       v-model="smaLength" :options="smaLengthOptions"></b-form-select>

                        <span style="font-weight: normal">  &nbsp;days</span>
                    </b-col>
                </b-form-row>
            </b-container>

        </b-popover>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        data: function () {
            return {
                smaLengthOptions: Array(300).fill(null).map((x, i) => ({value: i + 1, text: i + 1})),
                options: [
                    {value: true, text: "yes"},
                    {value: false, text: "no"}
                ]
            };
        },
        computed: {
            addSMA: {
                get() {
                    return this.$store.state[this.namespace].addSMA;
                },
                set(addSMA) {
                    this.$store.commit(this.namespace + '/setAddSMA', addSMA);
                }
            },
            smaLength: {
                get() {
                    return  this.$store.state[this.namespace].smaLength;
                },
                set(smaLength) {
                    this.$store.commit(this.namespace + '/setSmaLength', smaLength);
                }
            }
        }
    }
</script>
