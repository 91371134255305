var stockChartInitialState = {
    instrument: "stock",
    program: "StockCharts",
    // title: "W2021K/KW2021K",
    "p": [1, -1, 1, 1],
    "unitMove": [50, 50, 50, 50],
    "legs": 1,
    "sampleContract": ["W2021H", "W2021K", "MW2020U", "O2020U"],
    // "selected": ["W2021H/W2021K"],
    // stockArray: ["AAPL, Apple Inc.", "IBM, International Business Machines"],
    stockArray: ["AAPL, Apple Inc."],
    selected: ["AAPL, Apple Inc."],
    "mult": [1, 1, 1, 1],
    "y1": 15,
    "open": "2021-01-25",
    "close": "2021-03-26",
    "generator": "BasicBarsGenerator",
    "study": "basic",
    "language": "en",
    "hiddenAmchartsItems": [],
    "addBollinger": false,
    addSMA: true,
    smaLength: 20,
    "addCOTPanel": false,
    "addRSIPanel": false,
    "addMACDPanel": false,
    "addATRPanel": false,
    "addVolumePanel": false,
    "addCCIPanel": false,
    "addVolumeProfile": true,
    "showTradingPeriod": false,
    "showAligned": false,
    "overwriteShow": false,
    "rsiPeriod": 7,
    "cciPeriod": 20,
    "volumeProfileColumns": 24,
    "cciSource": "closes",
    "chartScrollbarEnabled": true,
    "showBullets": false,
    addHorizontalLine: false,
    "intracommodity": false,
    "stockGraphType": "ohlc",
    "showSeasonals": false,
    "seasonals": [5, 15],
    truncate: 3,
    dataSource: "barchart",
    chartParameters: {
        verticalScrollbar: true,
        firstChartDate: "2020-10-02",
        start: "2020-11-02",
        lastChartDate: "2021-03-02",
        skipEmptyPeriods: false,
        balloons: true,
        levels: [
            {from: "140", to: "145", color: "orange", name: "horizontal-bronze-series"},
            {from: "145", to: "150", color: "silver", name: "horizontal-silver-series"},
            {from: "150", to: "170", color: "gold", name: "horizontal-gold-series"}
        ],
        entryType: "stop"
    },
    search: {},
    user: "A4A-user0",
    password: "M1-K8xQgmR"
};

export {stockChartInitialState};