<template>
    <span>
        <b-form inline align-h="start"  style=" background-color: white; margin-bottom: 2px; width: min-content; margin-right: 5px; border: 1px solid gray;
                padding: 2px; white-space: nowrap; background-color: #E8E8E8">
            <b-col md="auto"> 
                <legs v-bind:max="2" v-bind:namespace="namespace" style="margin-right: 12px; padding-top: 2px"></legs>              

                <b-button-group  md="auto" v-for="n in $store.state[namespace].legs" v-bind:key="n">  

                  <!--  <position v-bind:namespace="namespace" v-bind:index="n-1" style="margin-right: 12px; margin-top: 3px"></position> -->

                    <span style="padding: 9px 2px 0 0; font-weight: bold"> shares:</span>
                    <mult v-bind:namespace="namespace" v-bind:index="n-1" style="margin: 5px 12px 0px 1px"></mult>

                    <!--       <position v-bind:index="n-1"></position> -->
                    <stock-auto-complete v-bind:index="n-1" v-bind:namespace="namespace" style="border: 1px solid gray; margin-right: 20px"></stock-auto-complete>
                </b-button-group >

                <b-button-group v-if="false" style="border: 0px solid gray;">
                    <b-spinner small style="margin: 11px 0px 7px 0px"></b-spinner> 
                </b-button-group>
                <!--   <b-button size=""   
                             v-on:click="update"
                             variant="info" style="padding: 2px; border:1px solid gray">
                       Get Chart
                   </b-button>  -->
            </b-col>
        </b-form>

    </span>
</template>

<script>
    import mult from '@/components/mult.vue';
   // import position from '@/components/position.vue';
    import legs from '@/components/legs';
    import stockAutoComplete from '@/components/stock-auto-complete';


    export default {
        props: {
            namespace: {
                type: String,
                default: "generalFormTab0"
            }
        },
        components: {
            legs, stockAutoComplete, mult/*, position*/
        },
        mounted: function () {
            console.log("stock-box.vue mounted() starting. this.namespace=", this.namespace);
        },
        data: function () {
            return {
                oldStockArray: []
            };
        }
    }
</script>

