<template>
    <div style="background-color: white; border: 1px solid gray; width: auto">

        <!--  <div style="width: 100%;"> activeModuleIndex:
                   <input style="width: 35px; border:1px solid gray; text-align: right"
                          v-model.number="activeModuleIndex">
               </div>
               program:<span style=" margin-left: 3px; margin-right: 16px">{{program}}</span>
               activeModuleName:<span style=" margin-left: 3px; margin-right: 16px">{{activeModuleName}}</span> -->
        <table>
            <tr>
                <td style="vertical-align: top; border-right: 1px solid gray;  background-color: #E8E8E8; padding: 4px;">
                    <div style="font-size: 2.2rem; font-weight: bold; color: darkblue; padding: 4px">Alpha4Charts</div>
                    <div style="font-size: 1.3rem; font-weight: normal; color: darkblue; padding: 4px">version 1.0.0</div>

                    <!--  <cui-user-menu /> 
                      <cui-language-switcher style="" />  -->
                    <hr>
            <saved-charts v-bind:namespace="activeModuleName" @newTab="newTabWithInitialState" 
                style="padding: 10px 0 0 0; border: 0px solid gray;"></saved-charts>
            </td>

            <td style="width: 100%; vertical-align: top; border: 0px solid gray; background-color: whitesmoke">             
            <b-tabs tabs id="tabs"
                    v-model='activeModuleIndex' 
                    active-nav-item-class="font-weight-bold"
                    active-tab-class="font-weight-bold"
                    style="margin-top: 3px;">

                <component v-if="!hideInput" v-bind:is="inputComponent"
                       v-bind:namespace="activeModuleName" 
                           v-bind:id="activeModuleName+'InputDiv'"
                           style="margin-top: 0px; padding: 2px 15px 3px 15px; border-bottom: 1px solid darkgray; width: 100%"></component>
                       
                <b-tab v-for="module in moduleArray" v-bind:key="module.moduleName" v-bind:ref="module.moduleName+'TabOutput'"
                       active style="border-top: 0px solid black">

                    <template #title>
                        <span style="margin: 1px">
                            <span style="margin-right: 3px; font-weight: bold; color: black;">
                                {{module.tabTitle}} 
                            </span> 
                            <span style="width: auto">
                                <b-button v-if="!['StartPage', 'Users'].includes(module.program) && typeof $store.state[module.moduleName] !== 'undefined' && activeModuleName === module.moduleName" 
                                          variant="dark" @click="toggleHideInput" style="height: auto; margin: 0px 3px 3px 1px; border: 1px solid darkgray; padding: 0 4px 0px 4px;">{{(hideInput ? "show" : "hide") + " input" }}</b-button> 

                                <b-spinner v-if="$store.state[module.moduleName].loading"
                                 style="width: 1.5rem; height: 1.5rem; margin: 0px 4px 0px 6px;"
                                  variant="primary" label="Spinner"></b-spinner>

                                <b-button size="sm" v-if="typeof $store.state[module.moduleName] !== 'undefined' && activeModuleName === module.moduleName"
                                          @click="closeTab(module.moduleName)" variant="dark" style=" height: 10px; width: 10px; margin: 0 0 4px 3px; padding-right: 2px;">

                                    <span class="h5 mb-2"><b-icon icon="x-square-fill" style="margin: -7px 0 19px -10px;"></b-icon> </span>


                                </b-button>
                            </span>
                        </span>
                    </template>

                    <div style="background-color: white">
                        <basic-chart-output v-if="module.program === 'BasicCharts'" v-bind:windowHeight="windowHeight" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></basic-chart-output>  
                        <calculator-output v-if="module.program === 'Calculator'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></calculator-output>  
                        <stock-chart-output v-if="module.program === 'StockCharts'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></stock-chart-output>  
                        <stacked-spreads-output v-if="module.program === 'StackedSpreads'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></stacked-spreads-output>  
                        <open-spreads-output v-if="module.program === 'OpenSpreads'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></open-spreads-output>  
                        <test-output v-if="module.program === 'TestCharts'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></test-output>  
                        <forward-curves-output v-if="module.program === 'ForwardCurves'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></forward-curves-output>  
                        <saved-charts-admin-output v-if="module.program === 'SavedChartsAdmin'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></saved-charts-admin-output>
                        <search-output v-if="module.program === 'Search'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName" @newTab="newTabWithInitialState"></search-output>  
                        <trade-maps-output v-if="module.program === 'TradeMaps'" v-bind:ref="module.moduleName+'TabOutput'" v-bind:namespace="module.moduleName"></trade-maps-output>  
                    </div>

                </b-tab>
                <template v-slot:tabs-end>
                    <b-nav-item v-b-modal.modal-1>
                        <b style="border: 0px solid darkgray; padding: 2px">+ add tab</b>
                    </b-nav-item>

                    <b-modal  size="sm" id="modal-1" ref="add-tab-model" title="Add Tab" style="color: black">
                        <table border="0">
                            <tr>
                                <td>
                                    <h5> Please select chart or study.</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                            <b-list-group v-model="newProgram" v-for="(value, i) in programOptions" v-bind:key="i">
                                <b-list-group-item button v-on:click="newTab(programOptions[i].value)">{{programOptions[i].text}}</b-list-group-item>
                            </b-list-group>
                            </td>
                            </tr>
                        </table>                              
                    </b-modal>

                </template>
            </b-tabs>
            </td>
            <td v-if="showStateEditor" style="vertical-align: top; height: 100%">                
            <state-editor v-bind:namespace="activeModuleName"></state-editor>
            </td>
            </tr>
        </table>
    </div>
</template>

<script>

    import generalForm from '../store/generalFormModule';
    //import moment from "moment";

    import basicChartInput from '@/components/programs/BasicChart/basic-chart-input';
    import stockChartInput from '@/components/programs/StockChart/stock-chart-input';
    import calculatorInput from '@/components/programs/Calculator/calculator-input';
    import stackedSpreadsInput from '@/components/programs/StackedSpreads/stacked-spreads-input';
    import openSpreadsInput from '@/components/programs/OpenSpreads/open-spreads-input';
    import forwardCurvesInput from '@/components/programs/ForwardCurves/forward-curves-input';
    import searchInput from '@/components/programs/Search/search-input';
    import startPageInput from '@/components/programs/StartPage/start-page-input';
    import tradeMapsInput from '@/components/programs/TradeMaps/trade-maps-input';
    import savedChartsAdminInput from '@/components/programs/SavedChartsAdmin/saved-charts-admin-input';
    import usersInput from '@/components/programs/Users/users-input';

    import basicChartOutput from '@/components/programs/BasicChart/basic-chart-output';
    import stockChartOutput from '@/components/programs/StockChart/stock-chart-output';
    import calculatorOutput from '@/components/programs/Calculator/calculator-output';
    import stackedSpreadsOutput from '@/components/programs/StackedSpreads/stacked-spreads-output';
    import openSpreadsOutput from '@/components/programs/OpenSpreads/open-spreads-output';
    import forwardCurvesOutput from '@/components/programs/ForwardCurves/forward-curves-output';
    import searchOutput from '@/components/programs/Search/search-output';
    import startPageOutput from '@/components/programs/StartPage/start-page-output';
    import tradeMapsOutput from '@/components/programs/TradeMaps/trade-maps-output';
    import savedChartsAdminOutput from '@/components/programs/SavedChartsAdmin/saved-charts-admin-output';
    import usersOutput from '@/components/programs/Users/users-output';

    import {basicChartInitialState} from '@/components/programs/BasicChart/basic-chart-initial-state';
    import {stockChartInitialState} from './programs/StockChart/stock-chart-initial-state';
    import {calculatorInitialState} from './programs/Calculator/calculator-initial-state';
    import {stackedSpreadsInitialState} from './programs/StackedSpreads/stacked-spreads-initial-state';
    import {openSpreadsInitialState} from './programs/OpenSpreads/open-spreads-initial-state';
    import {forwardCurvesInitialState} from '@/components/programs/ForwardCurves/forward-curves-initial-state';
    import {searchInitialState} from '@/components/programs/Search/search-initial-state';
    import {startPageInitialState} from '@/components/programs/StartPage/start-page-initial-state';
    import {tradeMapsInitialState} from '@/components/programs/TradeMaps/trade-maps-initial-state';
    import {savedChartsAdminInitialState} from '@/components/programs/SavedChartsAdmin/saved-charts-admin-initial-state';
    import {usersInitialState} from '@/components/programs/Users/users-initial-state';

    import savedCharts from '@/components/saved-charts';
    import StateEditor from '@/components/state-editor.vue';
    //import {kebabize} from "../js/main";
    import _ from 'lodash';
    import * as fb from '../firebase';

    export default {
        components: {
            savedCharts, basicChartOutput, stockChartOutput, calculatorOutput, stackedSpreadsOutput,
            openSpreadsOutput, forwardCurvesOutput, searchOutput, startPageOutput, tradeMapsOutput,
            savedChartsAdminOutput, StateEditor, usersOutput
        },
        created() {
            console.log("created() started.");
           /* this.$store.watch(
                    function (state) {
                        return state;
                    },
                    function (state) {
                        console.log("state is changing.");
                        // console.log("state=", state);
                        let stateArrayModuleNames = Object.keys(state).filter(x => x.includes("generalFormTab"));
                        // console.log("stateArrayModuleNames=", stateArrayModuleNames);
                        let stateArray = stateArrayModuleNames.map(x => state[x]);
                        // console.log("stateArray=", stateArray);
                        localStorage.stateArray = JSON.stringify(stateArray);
                    },
                    {
                        deep: true //add this if u need to watch object properties change etc.
                    }
            );*/

            window.addEventListener("resize", this.windowResizeEventHandler);
        },
        destroyed() {
            console.log("destroyed");
            window.removeEventListener("resize", this.windowResizeEventHandler);
        },
        mounted() {
            console.log("tabs-box.vue mounted starting. tradeMapsInitialState=", tradeMapsInitialState);
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                console.log('Modal is about to be hidden', bvEvent, modalId)
            });
          //  this.initializeStore();
        },
        data: function () {
            return{
                windowHeight: window.innerHeight,
                lastCheckTime: 0,
                newProgram: null,
                playbackControlKey: 0,
                programCompositionObject: {
                    BasicCharts: {input: basicChartInput, initialState: basicChartInitialState},
                    StackedSpreads: {input: stackedSpreadsInput, initialState: stackedSpreadsInitialState},
                    OpenSpreads: {input: openSpreadsInput, initialState: openSpreadsInitialState},
                    StockCharts: {input: stockChartInput, initialState: stockChartInitialState},
                    Calculator: {input: calculatorInput, initialState: calculatorInitialState},
                    ForwardCurves: {input: forwardCurvesInput, initialState: forwardCurvesInitialState},
                    SavedChartsAdmin: {input: savedChartsAdminInput, initialState: savedChartsAdminInitialState},
                    Search: {input: searchInput, initialState: searchInitialState},
                    StartPage: {input: startPageInput, initialState: startPageInitialState},
                    TradeMaps: {input: tradeMapsInput, initialState: tradeMapsInitialState},
                    Users: {input: usersInput, initialState: usersInitialState}
                }
            }
        },
        computed: {
            programOptions(){
                let options= [
                    {value: 'Calculator', text: 'Calculator'},
                    {value: 'BasicCharts', text: 'Futures Charts'},
                    {value: 'OpenSpreads', text: 'Open Spreads'},
                    {value: 'StackedSpreads', text: 'Stacked Spreads'},
                    {value: 'StockCharts', text: 'Stock Charts'},
                    {value: 'ForwardCurves', text: 'Forward Curves'},
                    {value: 'Search', text: 'Search'},
                    {value: 'StartPage', text: 'Start Page'},
                    {value: 'TradeMaps', text: 'Trade Maps'},
                ];

                if(['admin'].includes(this.$store.state.user.role)){
                    options.push({value: 'SavedChartsAdmin', text: 'Saved Charts Admin'});
                    options.push({value: 'Users', text: 'Users'});
                }
                return options.sort((a, b) => (a.text > b.text) ? 1 : -1);
            },
            showStateEditor(){
                return this.$store.state.showStateEditor;
            },
            hideInput: {
                get() {
                    let hideInput;
                    if (typeof this.activeModule !== 'undefined' && this.activeModule !== null) {
                        hideInput = this.activeModule.hideInput;
                    } else {
                        hideInput = false;
                    }
                    //  console.log("hideInput=", hideInput);
                    return hideInput;
                },
                set(hideInput) {
                    this.$store.commit(this.activeModuleName + '/setHideInput', hideInput);
                }
            },
            showPlaybackControl() {
                if (typeof this.activeModule !== 'undefined' && this.activeModule !== null) {
                    return this.activeModule.showPlaybackControl;
                } else {
                    return false;
                }
            },
            activeModuleIndex: {
                get() {
                    //  console.log("activeModuleIndex() get starting. this.$store.state.activeModuleIndex=", this.$store.state.activeModuleIndex);
                    return this.$store.state.activeModuleIndex;
                },
                set(activeModuleIndex) {
                    console.log("set activeModuleIndex()  starting. activeModuleIndex=", activeModuleIndex);

                    let currentTime = (new Date).getTime();
                    console.log("currentTime=", currentTime, " this.lastCheckTime=", this.lastCheckTime);
                    let timeIncrement = currentTime - this.lastCheckTime;
                    console.log("timeIncrement=", timeIncrement);

                    if (timeIncrement > 200) {
                        this.$store.commit('setActiveModuleIndex', activeModuleIndex);
                    }
                    this.lastCheckTime = (new Date).getTime();
                }
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            activeModule: {
                get() {
                    let activeModule = this.$store.state[this.activeModuleName];
                    console.log("activeModule=", activeModule);
                    return typeof activeModule !== 'undefined' ? activeModule : null;
                },
                set() {}
            },
            activeModuleJson(){
                console.log("activeModuleJson() starting.");
                let generalForm = JSON.parse(JSON.stringify(this.activeModule));
              //  delete generalForm.playback;
              //  delete generalForm.browserSideOnly;

                return JSON.stringify(generalForm);
            },
            moduleArray: {
                get() {
                    let moduleArray = this.$store.getters['moduleArray'];
                    // console.log("moduleArray=", moduleArray);
                    return moduleArray;
                },
                set() {
                    // console.log("program set() starting.");
                    // console.log("moduleArray=", moduleArray);
                }
            },
            inputComponent: {
                get() {
                    //  console.log("inputComponent set() starting. this.program=", this.program);
                    //  console.log("programCompositionObject=", this.programCompositionObject);
                    if (typeof this.moduleArray[this.activeModuleIndex] !== 'undefined') {
                        let  returnComponent = this.programCompositionObject[this.program].input;
                        console.log("returnComponent=", returnComponent);
                        return returnComponent;
                    } else {
                        return null;
                    }
                }
            },
            program: {
                get() {
                    if (typeof this.moduleArray[this.activeModuleIndex] !== 'undefined') {
                        return this.moduleArray[this.activeModuleIndex].program;
                    } else {
                        return null;
                    }
                },
                set() {
                    // console.log("program set() starting.");
                    // console.log("program=", program);
                }
            },
            inputDivHeight() {
                console.log("inputDivHeight() starting. this.activeModule=", this.activeModule);
                if (typeof this.activeModule !== 'undefined' && this.activeModule !== null) {
                    let inputDivHeight = this.activeModule.browserSideOnly.inputDivHeight;
                    console.log("inputDivHeight=", inputDivHeight);
                    return inputDivHeight;
                } else {
                    return null;
                }
            }
        },
        watch: {
            windowHeight: function (newWindowHeight, oldWindowHeight) {
                // console.log("watch newWindowHeight=", newWindowHeight, " oldWindowHeight=", oldWindowHeight);
                if (newWindowHeight !== oldWindowHeight) {
                    console.log("watch windowHeight=", newWindowHeight);
                    this.setChartHeight();
                }
            },
            inputDivHeight: function (inputDivHeight) {
                console.log("watch inputDivHeight=", inputDivHeight + " this.windowHeight=", this.windowHeight);
                this.setChartHeight();
            },
            showPlaybackControl: function (showPlaybackControl) {
                console.log("watch showPlaybackControl=", showPlaybackControl);
                this.setChartHeight();
            },
           /* activeModule: function (activeModule) {
                console.log("watch activeModule = ", activeModule);
               // this.setChartHeight();
            },*/
            activeModuleJson: function (newActiveModuleJson, oldActiveModuleJson) {
                 if (newActiveModuleJson !== oldActiveModuleJson) {
                    console.log("watching activeModuleJson");
                    this.storeTab(newActiveModuleJson);
                }
            }
        },
        methods: {
            storeTab: _.debounce(function() {
               // console.log("activeModuleJson=", activeModuleJson); 
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.activeModuleName]));
                    delete generalForm.browserSideOnly;
                    delete generalForm.playback;
                console.log("generalForm.moduleName=", generalForm.moduleName); 

                if(typeof generalForm.moduleName !== 'undefined'){
                    fb.db.collection('tabs')
                            .doc(this.$store.state.user.email)
                            .collection('tabs')
                            .doc(generalForm.moduleName)
                            .set(generalForm)
                            .then(() => {
                                console.log('tab saved.');
                            });
                }

            }, 999),
            windowResizeEventHandler(e) {
                //  console.log("window height=", e.target.innerHeight);
                this.windowHeight = e.target.innerHeight;
            },
            setChartHeight() {
                // console.log("this.activeModule.showPlaybackControl");
                console.log("setChartHeight() starting. this.inputDivHeight=", this.inputDivHeight,
                        " this.windowHeight=", this.windowHeight + " this.activeModuleName=", this.activeModuleName);
                if (this.program !== "TradeMaps" && this.activeModuleName !== null && this.inputDivHeight !== null) {
                    const elem = document.getElementById(this.activeModuleName + '-chartDiv');
                    const elem2 = document.getElementById(this.activeModuleName + '-plotlyRibbonChartDiv');
                    const elem3 = document.getElementById(this.activeModuleName + '-plotlyRibbonChartInstructions');

                    let playbackControlHeight = this.showPlaybackControl ? 33 : 0;
                    let pagePadding = 2 * 10; // See $page-padding in _variables.scss
                    let newHeight = (this.windowHeight - this.inputDivHeight - playbackControlHeight - pagePadding - 40); // The tab height is approximately 40px.
                    console.log('newHeight:', newHeight);

                    if (elem !== null) {
                        elem.style.height = newHeight + 'px';
                    }
                    if (elem2 !== null) {
                        let instructionsHeight = elem2 !== null ? elem3.offsetHeight : 0;
                        elem2.style.height = (newHeight - instructionsHeight) + 'px';
                    }
                }
            },
            toggleHideInput() {
                console.log("toggleHideInput() starting.");
                console.log("this.hideInput=", this.hideInput);
                this.$store.commit(this.activeModuleName + '/setHideInput', !this.hideInput);
            },
            /* savedChartsSidebarOpen() {
                console.log("savedChartsSidebarOpen() starting.");
            },
            savedChartsSidebarClose() {
                console.log("savedChartsSidebarClose() starting.");
                this.$store.commit('oldUser/setSelectedChart', null);
            }, */
            initializeStore() {
              /*  console.log("initializeStore() starting.");

                let stateArray;
                if (typeof localStorage.stateArray !== 'undefined') {
                    // console.log("localStorage.stateArray=", JSON.parse(localStorage.stateArray));
                  //  stateArray = JSON.parse(localStorage.stateArray);


                    if (stateArray.length === 0) {
                        stateArray = [startPageInitialState];
                    }
                } else {
                    stateArray = [startPageInitialState];
                }
                //  console.log("stateArray=", stateArray);

                stateArray.forEach((module, index) => {
                    console.log("index=", index, " module=", module);

                    let namespace = "generalFormTab" + index;
                    if (typeof this.$store.state !== 'undefined' && !Object.keys(this.$store.state).includes(namespace)
                            && module.sampleContract.length > 0) {
                        this.$store.registerModule(namespace, generalForm);

                        this.$store.commit(namespace + '/setGeneralForm', module);
                        this.$store.commit(namespace + '/setModuleName', namespace);

                        this.$store.dispatch(namespace + '/getContractListsFromServer');
                    }
                });*/
            },
            newTabWithInitialState(state) {
                console.log("newTabWithInitialState() starting. state=", state);

                let nextModuleId = this.$store.getters['nextModuleId'];
                console.log("nextModuleId=", nextModuleId);
                let nextModuleName = "generalFormTab" + nextModuleId;
                console.log("nextModuleName=", nextModuleName);

                if (!this.$store.hasModule(nextModuleName)) {
                    this.$store.registerModule(nextModuleName, generalForm);

                    this.$store.commit(nextModuleName + '/setGeneralForm', state);
                    this.$store.commit(nextModuleName + '/setModuleName', nextModuleName);
                    this.$store.dispatch(nextModuleName + '/getContractListsFromServer');
                
                 setTimeout(() => {
                        console.log("this.$refs=", this.$refs);
                        let refName = nextModuleName + 'TabOutput';
                        console.log("refName=", refName);
                        console.log("this.$refs[refName]=", this.$refs[refName]);

                        this.$refs[refName][this.$refs[refName].length - 1].activate();
                    }, 500);}
            },
            newTab(program) {
                console.log("newTab() starting.");
                console.log("program=", program);
                console.log("this.programCompositionObject=", this.programCompositionObject);
                console.log("this.programCompositionObject[program]=", this.programCompositionObject[program]);

                let initialState = this.programCompositionObject[program].initialState;
                console.log("initialState=", initialState);
                this.$refs['add-tab-model'].hide();
                this.newProgram = null;

                let nextModuleId = this.$store.getters['nextModuleId'];
                console.log("nextModuleId=", nextModuleId);
                let nextModuleName = "generalFormTab" + nextModuleId;
                console.log("nextModuleName=", nextModuleName);
                if (!this.$store.hasModule(nextModuleName)) {
                    console.log("generalForm=", generalForm);
                    this.$store.registerModule(nextModuleName, generalForm);
                    this.$store.commit(nextModuleName + '/setModuleName', nextModuleName);
                    this.$store.commit(nextModuleName + '/setGeneralForm', initialState);
                    this.$store.dispatch(nextModuleName + '/getContractListsFromServer');

                    setTimeout(() => {
                        console.log("this.$refs=", this.$refs);
                        let refName = nextModuleName + 'TabOutput';
                        console.log("refName=", refName);
                        console.log("this.$refs[refName]=", this.$refs[refName]);

                        this.$refs[refName][this.$refs[refName].length - 1].activate();
                    }, 500);
                }

            },
            closeTab(moduleName) {
                console.log("closeTab() starting. moduleName=", moduleName);
                //   this.activeModuleIndex--;
                //  console.log("this.activeModuleIndex=", this.activeModuleIndex);

                this.$store.commit(moduleName + '/setLoading', false);
                this.$store.unregisterModule(moduleName);

                fb.db.collection("tabs").doc(this.$store.state.user.email).collection("tabs").doc(moduleName).delete().then(() => {
                    console.log("chart deleted.");
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });
            }
        }
    };

</script>
