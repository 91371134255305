<template>
        <table style="width: 100%">
            <tr>
                <td style="vertical-align: top; width: 600px; background-color: white; margin: 0px; padding: 0px 2px 0 0">
            <contract-aligner-selector v-if="!hideInput"
                                       v-bind:namespace="namespace"
                                       v-bind:multiple="true"
                                       v-bind:title="'Aligner'"></contract-aligner-selector>
            </td>
            <td style="vertical-align: top; width: 50%; border-right: 1px solid darkgray;">
          <multiple-line-chart v-bind:namespace="namespace" 
                             v-bind:div-name="namespace+'-chartDiv'"
                             v-bind:parsedData="parsedData"></multiple-line-chart>  
        </td>

        <td style="vertical-align: top; width: 50%;">
         <plotly-ribbon-chart v-bind:namespace="namespace"
                              v-bind:parsedData="plotlyParsedData"></plotly-ribbon-chart> 
        </td>
            </tr>
        </table> 
</template>

<script>

    import contractAlignerSelector from '@/components/contract-aligner-selector';
    import $ from "jquery";
    import {spreadDigits} from "../../../js/main";
    import multipleLineChart from '@/components/am4charts-multiple-line-chart';
    import plotlyRibbonChart from '@/components/plotly-ribbon-chart';

    export default {
        components: {
            contractAlignerSelector, multipleLineChart, plotlyRibbonChart
        },
        mounted() {
            console.log("stacked-spreads-output.vue mounted() starting.");
            this.$store.commit(this.namespace + '/setTabTitle', "stacked: " + this.stackedTabTitle + "...");

            if (this.activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                    this.getData();
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                parsedData: null,
                plotlyParsedData: null,
                initialized: false,
                data: [],
                layout: {},
                modeBarButtonsToRemove: ['sendDataToCloud', 'orbitRotation', 'pan3d', 'hoverClosest3d'],
            }
        },
        computed: {
            start() {
                return this.$store.state[this.namespace].chartParameters.start;
            },
            end() {
                return this.$store.state[this.namespace].chartParameters.end;
            },
            hideInput() {
                return this.$store.state[this.namespace].hideInput;
            },
            selected() {
                return this.$store.state[this.namespace].selected;
            },
            stackedTabTitle() {
                let title = this.selected[this.selected.length - 1];
                console.log("title=", title);
                return title;
            },
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            normalization() {
                return this.$store.state[this.namespace].normalization;
            },
            normalizationMonth() {
                return this.$store.state[this.namespace].normalizationMonth;
            },
            normalizationDate() {
                return this.$store.state[this.namespace].normalizationDate;
            }
        },
        watch: {
            start: function (start) {
                console.log("watch start=", start);
                let end = this.$store.state[this.namespace].chartParameters.end;

                let newParsedData = JSON.parse(JSON.stringify(this.parsedData));
                newParsedData.values = newParsedData.values.filter(x => x[0] > start && x[0] < end);
                this.plotlyParsedData = newParsedData;
            },
            end: function (end) {
                console.log("watch end=", end);
                let start = this.$store.state[this.namespace].chartParameters.start;

                let newParsedData = JSON.parse(JSON.stringify(this.parsedData));
                newParsedData.values = newParsedData.values.filter(x => x[0] > start && x[0] < end);
                this.plotlyParsedData = newParsedData;
            },
            selected: function (newSelected, oldSelected) {
                console.log("watch newSelected=", JSON.parse(JSON.stringify(newSelected)), " oldSelected=", JSON.parse(JSON.stringify(oldSelected)));
                //   if (JSON.parse(JSON.stringify(newSelected)) !== JSON.parse(JSON.stringify(oldSelected))){
                if (newSelected !== oldSelected && newSelected.length > 0) {
                    this.$store.commit(this.namespace + '/setTabTitle', "stacked: " + this.stackedTabTitle + "...");
                    this.getData();
                }
            },
            ticker: function (newTicker, oldTicker) {
                console.log("watch: newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);
                if (this.selected === [] || newTicker === null) {
                    this.plotlyParsedData = {};
                    this.parsedData = {};
                } else {
                    this.getData();
                }
            },
            activeModuleName: function (activeModuleName) {
                console.log("watch activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                    this.getData();
                }
            },
            normalization: function (normalization) {
                console.log("watch normalization = ", normalization);
                this.getData();
            },
            normalizationMonth: function (normalizationMonth) {
                console.log("watch normalizationMonth=", normalizationMonth);
                this.getData();
            },
            normalizationDate: function (normalizationDate) {
                console.log("watch normalizationDate=", normalizationDate);
                this.getData();
            }
        },
        methods: {
            getData() {
                console.log("getData() starting.");
                // console.trace();
                this.$store.commit(this.namespace + '/setLoading', true);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                generalForm.program = "StackedSpreads";
                generalForm.study = "stacked";
                generalForm.chartType = "amcharts";
                delete generalForm.browserSideOnly;
                console.log("generalForm=", generalForm);

                var that = this;
                if (generalForm.selected.length === 0) {
                    this.plotlyParsedData = {};
                    this.parsedData = {};
                } else {
                    let json = JSON.stringify(generalForm, null, 2);
                    let url = this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/ControllerServlet2";
                    $.ajax({
                        url: url,
                        type: "POST",
                        data: {"json": json},
                        success: function (data) {
                            that.$store.commit(that.namespace + '/setLoading', false);
                            let parsedData = JSON.parse(data);
                            console.log("parsedData=", JSON.parse(JSON.stringify(parsedData)));

                        if(typeof parsedData.values !== 'undefined'){
                            console.log("generalForm.selected[0]=", generalForm.selected[0]);
                            let digits = spreadDigits(generalForm.selected[0]);
                            console.log("digits=", digits);
                           
                            parsedData.values.forEach(x => {
                                if (x[1] !== null) {
                                    x[1] = x[1].toFixed(digits);
                                }
                            });
                            console.log("parsedData=", JSON.parse(JSON.stringify(parsedData)));
                            parsedData.eye = that.$store.state[that.namespace].chartParameters.eye;
                        }

                            that.parsedData = JSON.parse(JSON.stringify(parsedData));
                            that.plotlyParsedData = JSON.parse(JSON.stringify(parsedData));
                        },
                        fail: function (data) {
                            console.log("ajax call failed.");
                            that.$store.commit(that.namespace + '/setLoading', false);
                            $("#progress").hide();
                            $("#button").show();
                            console.log(data);
                        }
                    });
                }
            }
        }
    };

</script>
