<template>
    <div>
        <table border="1" style="width: 100%; color: black" >
            <!--   <tr>
                   <td style="text-align: center;  padding: 3px 10px 3px 10px;">ticker</td>
                   <td colspan="2" style="color: red">{{ticker}}</td>
               </tr> -->
            <tr style="font-weight: bold; background-color: lightgray">  
                <td style="text-align: center; width: 90px">open</td>
                <td style="text-align: center; width: 90px">close</td>
                <td style="text-align: center; width: 70px">avg days</td>
                <td v-bind:style="{backgroundColor: avgChange < 0 ? 'red' : 'black' }" style="text-align: center;  color: white">avg change</td>
                <td v-bind:style="{backgroundColor: avgChange < 0 ? 'red' : 'black' }" style="text-align: center;  color: white">appd</td>
                <td style="text-align: center; ">up</td>
                <td style="text-align: center; ">avg mpe</td>
                <td style="text-align: center; ">best mpe</td>
                <td style="text-align: center; ">avg mae</td>
                <td style="text-align: center; ">worst mae</td>
            </tr>

            <tr style=" padding: 3px 10px 3px 10px">
                <td style="text-align: center;   white-space: nowrap">{{open}}</td>
                <td style="text-align: center;   white-space: nowrap">{{close}}</td>
                <td style="text-align: center;  ">{{avgDays}}</td>
                <td style="text-align: center;  " v-bind:style="{color: avgChange < 0 ? 'red' : 'black' }">{{displayMoneyFormat(avgChange)}}</td>
                <td style="text-align: center;  " v-bind:style="{color: avgAppd < 0 ? 'red' : 'black' }">{{displayMoneyFormat(avgAppd)}}</td>
                <td style="text-align: center;  ">{{percentUp}}%</td>
                <td style="text-align: center;  ">{{displayMoneyFormat(avgMax)}}</td>
                <td style="text-align: center;  ">{{displayMoneyFormat(bestMax)}}</td>
                <td style="text-align: center; color: red ">{{displayMoneyFormat(avgMin)}}</td>
                <td style="text-align: center; color: red ">{{displayMoneyFormat(worstMin)}}</td>
            </tr>
        </table>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        mounted: function () {
            console.log("trade-statistics.vue mounted() starting.");
        },
        data: function () {
            return {
                open: null,
                close: null,
                numberOfTrades: null,
                percentUp: null,
                percentDown: null,
                avgAppd: null,
                avgChange: null,
                avgMax: null,
                bestMax: null,
                avgMin: null,
                worstMin: null,
                avgDays: null
            }
        },
        methods: {
            customToFixed: function (number) {
                console.log("number=", number);
                let returnValue;
                if (isNaN(number) || null == number) {
                    // console.log("missing data.");
                    returnValue = "\u00A0\u00A0------\u00A0\u00A0 ";
                } else {
                    returnValue = number.toFixed(this.digits);
                }
                // console.log("returnValue=", returnValue);
                return returnValue;
            },
            displayMoneyFormat: function (number) {
                // console.log("number=", number);
                let returnValue;
                if (isNaN(number) || null == number) {
                    // console.log("missing data.");
                    returnValue = "------\u00A0\u00A0\u00A0 ";
                } else {
                    returnValue = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(number);
                }
                // console.log("returnValue=", returnValue);
                return returnValue;
            },
            setStatistics: function (tradeStatistics) {
               // console.log("tradeStatistics=", {...tradeStatistics});
                // this.numberOfTrades = 0;
                if (Object.keys(tradeStatistics).length > 2) {
                    this.open = moment(tradeStatistics.open, "YYYY-MM-DD").format("MMM D, YYYY");
                    this.close = moment(tradeStatistics.close, "YYYY-MM-DD").format("MMM D, YYYY");
                    this.upNumber = 0;
                    this.avgDays = tradeStatistics.avgDays.toFixed(3);
                    this.percentUp = tradeStatistics.percentUp;
                    this.percentDown = tradeStatistics.percentDown;
                    this.avgChange = tradeStatistics.avgChange;
                    this.avgAppd = tradeStatistics.avgChange / tradeStatistics.avgDays;
                    this.avgMin = tradeStatistics.avgMin;
                    this.worstMin = tradeStatistics.worstMin;
                    this.avgMax = tradeStatistics.avgMax;
                    this.bestMax = tradeStatistics.bestMax;
                } else {
                    this.open = moment(tradeStatistics.open, "YYYY-MM-DD").format("MMM D, YYYY");
                    this.close = moment(tradeStatistics.close, "YYYY-MM-DD").format("MMM D, YYYY");
                    this.upNumber = "---";
                    this.avgDays = "-----";
                    this.percentUp = "----";
                    this.percentDown = "----";
                    this.avgChange = "----";
                    this.avgAppd = "----";
                    this.avgMin = "----";
                    this.worstMin = "----";
                    this.avgMax = "----";
                    this.bestMax = "----";
                }
            }
        }

    }
</script>
