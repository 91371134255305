<template>
    <span>
        <auto-complete
            v-model="selectedStock"
            :data-source="dataSource"
            style="width: 360px; 1px solid darkgray; font-size: 1.1rem; background-color: white"
            placeholder="input here"
            @select="onSelect"
            @search="onSearch"
            @change="onChange"
            />

    </span>
</template>

<script>
    import $ from "jquery";
    import { AutoComplete } from 'ant-design-vue';

    export default {
        components: {
            AutoComplete
        },
        mounted() {
            console.log("stock-auto-complete.vue starting.");
            //  console.log("this.namespace =  ", this.namespace);
            // this.getInstrumentDefinitionsFromBarchart();

            this.selectedStock = this.$store.state[this.namespace].stockArray[this.index];
        },
        props: ['namespace', 'index'],
        data() {
            return {
                selectedStock: '',
                dataSource: [],
                autoCompleteList: []
            };
        },
        watch: {
            selectedStock(val) {
                console.log('selectedStock=', val);
                //  this.getHistory(val.split(',')[0]);
            }
        },
        methods: {
            onSearch(searchText) {
                let capitalizedSearchText = searchText.toUpperCase();
                console.log("onSearch() starting. capitalizedSearchText=", capitalizedSearchText);
                this.getInstrumentDefinitionsFromBarchart(searchText);
            },
            onSelect(value) {
                console.log('onSelect', value);
                let ticker = value.split(',')[0];
                console.log("ticker=", ticker);

                let tickerArray = [...this.autoCompleteList].map(x => x.split(',')[0]);
                console.log("tickerArray=", tickerArray);

                if (this.selectedStock.includes(',') && tickerArray.includes(ticker)) {
                    // this.getHistory(ticker);
                    var newArray = this.$store.state[this.namespace].stockArray.slice(0);
                    //  console.log("autoCompleteList=", this.autoCompleteList);
                    let listString = this.autoCompleteList.find(x => x.split(',')[0] == ticker);
                    //  console.log("listString=", listString);

                    newArray[this.index] = listString;
                    console.log("newArray=", newArray);

                    this.$store.commit(this.namespace + '/setStockArray', newArray);
                    let selected = [];
                    selected.push(newArray.slice(0, this.$store.state[this.namespace].legs).join("/"));
                    console.log("selected=", selected);
                    this.$store.commit(this.namespace + '/setSelected', selected);
                }

                // this.getHistory(ticker);
            },
            onChange(value) {
                console.log('onChange', value);
            },
            getInstrumentDefinitionsFromBarchart(keyword) {
                var that = this;
                console.log("api call for auto-complete.");
                $.ajax({
                    // url: baseUrl + "/node/get-auto-complete",
                    // url: "https://ondemand.websol.barchart.com/getInstrumentDefinition.json?apikey=b1c49578e1c86ad86ed719375aff6f49&exchanges=NYSE,NASDAQ&maxRecords=10000",
                    // url: "https://test.charts.alpha4all.com/instrument_definition.php?exchanges=NYSE,NASDAQ&maxRecords=1000",
                    // url: "http://localhost:8080/StockDefinitionsServlet?letter=" + letter,
                   // url: "https://dscarr.com/StockDefinitionsServlet?letter=" + letter,
                      url: "https://ondemand.websol.barchart.com/getSymbolLookUp.json?apikey=b1c49578e1c86ad86ed719375aff6f49&keyword="+keyword+"&exchanges=NASDAQ,NYSE,AMEX",
                    type: "GET",
                    success: function (data) {
                        console.log("data=", data);
                        let temp = data.results.map(x => x.symbol + ",  " + x.name).sort();
                        //  console.log("temp=", temp);
                        that.autoCompleteList = temp;
                        that.dataSource = temp;

                    }
                });
            }
        }
    };


</script>