<template>
    <span>                                                         

        <b-form-select style="border:1px solid gray; color: black;"
                       v-model="selected" :options="listTickerOptionsNoLegSwapping"
                       size="sm"></b-form-select>

    </span>
</template>

<script>
    import {spreadTitle, isSpreadOpen} from "../js/main";

    export default {
        beforeMount: function () {
            // console.log("contract-selector-single-select.vue beforeMount() starting. this.openOnly=", this.openOnly)
        },
        props: {
            namespace: {
                type: String,
                default: ""
            },
            openOnly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            listTickerOptionsNoLegSwapping() {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // console.log("this.openOnly=", this.openOnly);
                let listOptions = this.$store.getters[this.namespace + '/listOptions'];
                let options;

                if (this.openOnly) {
                    let listOpenOptions = listOptions.filter(x => x.open);
                    options = listOpenOptions.length > 0 ? listOpenOptions : [listOptions[0]];
                } else {
                    options = listOptions;
                }

                return options.map(x => {
                    // console.log("x=", x);
                    generalForm.selected = [x.value];
                    let invertDatesMultiplier = 1;
                    let noLegSwapping = true;
                    let ticker = spreadTitle(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping);
                    // console.log("ticker=", ticker);

                    return {value: x.value, text: ticker};
                });
            },
            selected: {
                get() {
                    let selected = this.$store.state[this.namespace].selected;
                    console.log("selected=", [...selected]);
                    let isOpen;
                    if (selected.length > 0 ) {
                        isOpen = isSpreadOpen(selected[0]);
                    }
                    if (selected == "" || selected.length == 0 || typeof selected == 'undefined' || !isOpen) {
                        let openList = this.$store.getters[this.namespace + '/listOptions'].filter(x => x.open === true);
                        console.log("this.$store.getters[this.namespace + '/listOptions']=", this.$store.getters[this.namespace + '/listOptions']);
                        console.log("openList=", openList);
                        if (openList.length > 0) {
                            selected[0] = openList.slice(-1)[0].value;
                        } else {
                            selected[0] = this.$store.getters[this.namespace + '/listOptions'].filter(x => x.open === false)[0].value;
                        }
                        console.log("generalForm.selected=", selected);
                    }
                    return selected[0];
                },
                set(selected) {
                    console.log("selected set() starting. selected=", selected);
                    let temp = [];
                    temp[0] = selected;
                    this.$store.commit(this.namespace + '/setSelected', temp);
                    //  updateChart();
                    // }
                }
            }
        }
    }
</script>
