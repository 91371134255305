<template>
    <div style="margin-top: 3px">

        <trade-statistics ref="statistics" style="width: auto; margin: 0px 2px 3px 3px"></trade-statistics>

        <trade-statistics-column-chart v-bind:parsedData="tradeStatisticsChartParsedData"
                                       v-bind:namespace="namespace"
                                       v-bind:tableExtrema="tableExtrema"
                                       style="border: 1px solid darkgray; margin: 0 2px 0 3px;"></trade-statistics-column-chart>
        
        <div style="border: 1px solid darkgray; margin: 3px">
            <div v-bind:id="namespace+'-plotlyRibbonChartInstructions'" 
            style="font-size: 1.1rem; font-weight: normal; margin: 2px">
            To <b>select</b> a trade, click on the chart.
            To <b>zoom</b>, click and drag on the chart. To <b>unzoom</b>, click on the house icon.
        </div>
        <div v-bind:id="divName" v-bind:ref="divName" 
                 style="width: 780px; height: 640px; margin: 2px"></div>
        </div>
    </div>
</template>

<script>
    import  Plotly  from 'plotly.js';
    import {spreadTitle, transpose} from "../js/main";
    import $ from "jquery";
    import Moment from 'moment';
    import { extendMoment } from 'moment-range';
    import tradeStatisticsColumnChart from '@/components/trade-statistics-column-chart';
    import tradeStatistics from '@/components/trade-statistics';

    const moment = extendMoment(Moment);

    export default {
        components: {
            tradeStatisticsColumnChart, tradeStatistics
        },
        mounted() {
            console.log("plotly-heatmap.vue mounted() starting.");
            // Plotly.plot(this.divName, this.data);
        },
        props: ['namespace', 'parsedData'],
        data: function () {
            return {
                initialized: false,
                showPlot: true,
                tableExtrema: null,
                divName: this.namespace + '-plotlyHeatMapDiv',
                tradeStatisticsChartParsedData: null,
            };
        },
        watch: {
            parsedData: function (parsedData) {
                console.log("watch parsedData=", JSON.parse(JSON.stringify(parsedData)));
                if (typeof parsedData !== 'undefined') {
                    this.runPlotly(parsedData);
                } else {
                    this.showPlot = false;
                }
            }
        },
        methods: {
            removeHeatmap() {
                Plotly.purge(this.divName);
            },
            runPlotly(vectorMap, direction, parameterToMap) {
                console.log("runPlotly() starting.");
                console.log("vectorMap =", vectorMap);
                this.removeHeatmap();
                this.showPlot = true;

                let newTradeObject = {};
                let upValue;
                let downValue;
                if (direction === "down") {
                    for (let open in vectorMap) {
                        let openVector = vectorMap[open];
                        // console.log("open=", open + ':', openVector);
                        for (let close in openVector) {
                            //  console.log(open + ':', closeVector[open]);
                            upValue = openVector[close];
                            downValue = Object.assign({}, openVector[close])

                            downValue["appd"] = -upValue.avgChange / upValue.avgDays;
                            downValue["close"] = close;
                            downValue["avgChange"] = -upValue.avgChange;
                            downValue["avgMax"] = -upValue.avgMin;
                            downValue["bestMax"] = -upValue.worstMin;
                            downValue["avgMin"] = -upValue.avgMax;
                            downValue["worstMin"] = -upValue.bestMax;
                            downValue["rrr"] = -upValue.avgMax / upValue.avgMin;
                            downValue["percentUp"] = upValue.percentDown;
                            downValue["percentDown"] = upValue.percentUp;
                            //  console.log("close=",close, " downValue=", downValue);
                            openVector[close] = downValue;
                        }
                        newTradeObject[open] = openVector;
                    }
                    // console.log("newTradeObject=", newTradeObject);
                    vectorMap = newTradeObject;
                } else {
                    for (let open in vectorMap) {
                        let openVector = vectorMap[open];
                        // console.log("open=", open + ':', openVector);
                        for (let close in openVector) {
                            // console.log(close + ':', openVector[close]);
                            upValue = openVector[close];
                            upValue["open"] = open;
                            upValue["appd"] = upValue.avgChange / upValue.avgDays;
                            upValue["rrr"] = -upValue.avgMax / upValue.avgMin;
                            openVector[close] = upValue;
                        }
                        if (Object.keys(openVector).length !== 0) {
                            newTradeObject[open] = openVector;
                        }
                    }
                    //  console.log("newTradeObject=", newTradeObject);
                    vectorMap = newTradeObject;
                }
                // console.log("vectorMap=", vectorMap);

                let columnExtrema = Object.values(vectorMap).map(column => {
                    let columnValues = Object.values(column);
                    // console.log("columnValues=", JSON.parse(JSON.stringify(columnValues)));
                    return {
                        maxBestMax: Math.max(...columnValues.map(trade => trade.bestMax), 0),
                        maxAvgMax: Math.max(...columnValues.map(trade => trade.avgMax), 0),

                        minWorstMin: Math.min(...columnValues.map(trade => trade.worstMin), 0),
                        minAvgMin: Math.min(...columnValues.map(trade => trade.avgMin), 0)
                    };
                });
                console.log("columnExtrema=", columnExtrema);

                let tableExtrema = {
                    maxBestMax: Math.max(...columnExtrema.map(x => x.maxBestMax), 0),
                    maxAvgMax: Math.max(...columnExtrema.map(x => x.maxAvgMax), 0),

                    minWorstMin: Math.min(...columnExtrema.map(x => x.minWorstMin), 0),
                    minAvgMin: Math.min(...columnExtrema.map(x => x.minAvgMin), 0)
                };
                console.log("tableExtrema=", tableExtrema);
                this.tableExtrema = tableExtrema;

                let startDate = (Object.keys(vectorMap)[0]);
                console.log("startDate=", startDate);
                let endDate = Object.keys(vectorMap)[Object.keys(vectorMap).length - 1];
                console.log("endDate=", endDate);
                let openRange = moment().range(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
                let open = Array.from(openRange.by("days")).map(date => date.format("YYYY-MM-DD"));
                // console.log("open=", open);
                let closeRange = moment().range(moment(startDate).add(3, 'days'), moment(endDate).add(3, 'days').format("YYYY-MM-DD"));
                let close = Array.from(closeRange.by("days")).map(date => date.format("YYYY-MM-DD"));
                // console.log("close=", close);
                // let vectorMapValues = Object.values(vectorMap);
                //console.log("vectorMapValues=", vectorMapValues);

                let obj = {};
                let nullVector = close.map(function (date) {
                    obj[date] = {"target": ""};
                    // console.log("obj=", obj);
                    return obj;
                })[0];
                //  console.log("nullVector=", nullVector);

                let target = parameterToMap;
                console.log("target=", target);
                let values = Object.keys(vectorMap).map(function (close) {
                    //  console.log("close=", close);
                    let tradeVector = vectorMap[close];
                    let newTradeObject = {};
                    Object.keys(tradeVector).forEach(function (open) {
                        let value = tradeVector[open][target];
                        // console.log("close=", close, "  value=", value);
                        newTradeObject[open] = value;
                    });
                    //   console.log("newTradeObject=", newTradeObject);

                    let fullTradeVector = {...nullVector, ...newTradeObject};
                    return Object.values(fullTradeVector);
                });
                //console.log("values=", values);
                let text = Object.keys(vectorMap).map(function (close) {
                    //console.log("close=", close);
                    let tradeVector = vectorMap[close];
                    let newTradeObject = {};
                    Object.keys(tradeVector).forEach(function (open) {
                        let value = tradeVector[open];
                        //  value["appd"] = value.avgChange / value.avgDays;
                        //  value["close"] = close;
                        value["target"] = value[target];
                        // console.log("close=", close,"  value=",value);
                        newTradeObject[open] = value;
                    });
                    //   console.log("newTradeObject=", newTradeObject);

                    let fullTradeVector = {...nullVector, ...newTradeObject};
                    return Object.values(fullTradeVector);
                });
                // console.log("text=", text);

                let map = {};
                map.open = open;
                map.close = close;
                map.values = transpose(values);
                // console.log("map.values=", map.values);

                let form = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                let title = spreadTitle(form);
                // console.log("title=", title);
                let parameterOptions=this.$store.state.siteData.parameterOptions;
                let parameterToMapText=parameterOptions.find(x => x.value === parameterToMap).text;
                console.log("parameterToMapText=", parameterToMapText);

                map.title = {
                    text: "<b>" + title + "</b>: " 
                    + parameterToMapText + " map",
                    font: {
                        // family: 'Courier New, monospace',
                        family: 'Tahoma, sans-serif',
                        size: 12
                    }
                };
                // console.log("map=", map);
                // console.log("form=", form);

                if (map.values == "no map") {
                    $("#progress").hide();
                    $("#button").show();
                    let   temp1 = "<div style='white-space: nowrap; color: red; font-size: 11px; padding:5px; font-weight: bold'>Not enough data to make map.</div>";
                    $('#' + this.divName).html(temp1);
                    $('#' + this.divName).show();
                    return;
                }

                let textColor = 'black';
                let colorscaleValue = [
                    ['0.0', 'rgb(165,0,38)'],
                    ['0.111111111111', 'rgb(255,0,0)'],
                    ['0.222222222222', 'rgb(245,80,0)'],
                    ['0.333333333333', 'rgb(235,150,0)'],
                    ['0.444444444444', 'rgb(225,185,0)'],
                    ['0.555555555556', 'rgb(215, 235, 0)'],
                    ['0.666666666667', 'rgb(200, 235, 0)'],
                    ['0.777777777778', 'rgb(0, 255, 0)'],
                    ['0.888888888889', 'rgb(0, 204, 0)'],
                    ['1.0', 'rgb(0, 153, 0)']
                ];
                let that = this;
                let data = (function () {
                    let returnObject = [{
                            z: map.values,
                            x: map.open,
                            y: map.close,
                            text: transpose(text),
                            type: 'heatmap',
                            colorscale: colorscaleValue,
                            hoverinfo: 'text'
                        }];
                    console.log("returnObject=", returnObject);
                    delete returnObject[0].hoverinfo;
                    console.log(`%{x}`);
                    returnObject[0].hovertemplate = `<span style="font-family: Tahoma, sans-serif; font-size: 10px"><b>open</b>: %{x}<br><b>close</b>: %{y}` +
                            '<br><b>' + parameterToMapText + '</b>: %{text.target}<extra></extra></span>'; // <extra></extra> removes the "trace0" from the popup. 
                    return returnObject;
                })();
                this.data = data;

                let layout = {
                    font: {
                        family: 'Tahoma, sans-serif',
                        size: 11
                    },
                    xaxis: {
                        type: 'date',
                        title: 'open'
                    },
                    yaxis: {
                        type: 'date',
                        title: 'close'
                    },
                    title: map.title,
                    annotations: [],
                    hoverlabel: {
                        bgcolor: "#FFF",
                        align: "left"
                    }

                };

                let language = "en";
                let config = {
                    locale: language,
                    displayModeBar: true,
                    showAxisDragHandles: false,
                    modeBarButtonsToRemove: ['sendDataToCloud', 'toggleSpikelines', 'pan2d', 'hoverClosest2d', 'zoom2d', 'zoomIn2d', 'zoomOut2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'autoScale2d'],
                    showLink: false,
                    responsive: true,
                    displaylogo: false
                };
                let xIndex = map.open.indexOf(form.open);
                let yIndex = map.close.indexOf(form.close);
                console.log("form.open=", form.open + " form.close=", form.close);
                console.log("xIndex=", xIndex + " yIndex=", yIndex);
                if (xIndex === -1 || yIndex === -1) {
                    xIndex = 0;
                    form.open = map.open[xIndex];

                    yIndex = map.close.length - 1;
                    form.close = map.close[yIndex];
                }
                /*  console.log("xIndex=", xIndex + " yIndex=", yIndex);
                 console.log("map[xIndex][yIndex]=", values[xIndex][yIndex]);
                 console.log("map.open[xIndex]=", map.open[xIndex]);
                 console.log("map.close[yIndex]=", map.close[yIndex]);
                 console.log("xIndex=", xIndex + " yIndex=", yIndex, "  map[" + xIndex + "][" + yIndex + "]=", values[xIndex][yIndex]);
                 
                 console.log("form.open=", form.open);
                 console.log("form.close=", form.close);*/

                console.log("xIndex=", xIndex + " yIndex=", yIndex, "  map[" + xIndex + "][" + yIndex + "]=", values[xIndex][yIndex]);
                while (values[xIndex][yIndex].target == "" && yIndex > 0) {
                    // console.log("xIndex=", xIndex + " yIndex=", yIndex, "  map[" + xIndex + "][" + yIndex + "]=", values[xIndex][yIndex]);
                    //  xIndex++;
                    yIndex--;
                    form.open = map.open[xIndex];
                    form.close = map.close[yIndex];
                }
                this.$store.commit(this.namespace + '/setOpen', form.open);
                this.$store.commit(this.namespace + '/setClose', form.close);

                if (typeof updateOtherCharts !== "undefined") {
                    // updateOtherCharts();
                }

                // console.log("transpose(text)=", transpose(text));
                // console.log("text=", text);
                // console.log("map.values=", map.values);

                let result = {
                    xref: 'x1',
                    yref: 'y1',
                    x: form.open,
                    y: form.close,
                    text: '<b>open</b>: ' + moment(form.open).locale(language).format("MMM D, YYYY") +
                            '<br><b>close</b>: ' + moment(form.close).locale(language).format("MMM D, YYYY") +
                            '<br><b>' + parameterToMapText + '</b>: ' + transpose(text)[yIndex][xIndex][parameterToMap],
                    showarrow: true,
                    font: {
                        color: textColor,
                        size: 10
                    },
                    bgcolor: 'white',
                    bordercolor: 'black',
                    borderwidth: 1,
                    borderpad: 3,
                    align: 'left'
                };
                if (typeof form.open !== 'undefined') {
                    layout.annotations.push(result);
                }

                // this.divName = this.namespace + '-plotlyHeatMapDiv';
                Plotly.newPlot(this.divName, data, layout, config);
                $('#' + this.divName).show();
                //  $('#temp2').show();
                $("#chartdivfixed").css("visibility", "visible");


                //  let that = this;
                document.getElementById(this.divName).on('plotly_click', function (event) {
                    $("#button").hide();
                    $("#progress").show();
                    setTimeout(function () {
                        handleClick(event, that);
                    }, 100);
                });

                callAuxiliaryComponents(form.open, form.close);

                document.getElementById(this.divName).on('plotly_hover', function (data) {
                    // console.log("data.points[0]=", data.points[0]);
                    let open = data.points[0].x;
                    let close = data.points[0].y;
                    // console.log("open=", open);
                    // console.log("close=", close);
                    callAuxiliaryComponents(open, close);
                });

                function callAuxiliaryComponents(open, close) {
                    // console.log("vectorMap=", vectorMap);
                    // eslint-disable-next-line 
                    let openVector = vectorMap[open];
                    //  console.log("openVector=", openVector);
                    let containsClose = Object.keys(openVector).includes(close);
                   // console.log("containsClose=", containsClose);

                    if (containsClose) {
                        let tradeStatistics = JSON.parse(JSON.stringify(vectorMap[open][close]));
                        //  console.log("tradeStatistics=", {...tradeStatistics});
                        if (typeof tradeStatistics !== 'undefined') {
                            tradeStatistics.open = open;
                            let test = JSON.stringify(tradeStatistics);
                           // console.log("tradeStatistics=", tradeStatistics);
                            if (typeof test !== 'undefined') {
                                that.tradeStatisticsChartParsedData = tradeStatistics;
                                that.$refs.statistics.setStatistics(tradeStatistics);
                            }
                        }
                    } else {
                        that.tradeStatisticsChartParsedData = {};
                        that.$refs.statistics.setStatistics({open: open, close: close});
                    }
                }

                function handleClick(event, that) {
                    console.log("handleClick() starting.");
                    let open = moment(event.points[0].x);
                    let close = moment(event.points[0].y);
                    let value = event.points[0].z;
                    console.log("open=", event.points[0].x + " close=", event.points[0].y + "  value=", value);

                    let point = vectorMap[event.points[0].x][event.points[0].y];
                    console.log("point=", point);

                    if (null === value) {
                        //  $("#progress").hide();
                        alert('Not enough trades.');
                        $("#button").show();
                        $("#progress").hide();
                        return;
                    } else {

                        let openDate = open.format("D");
                        let openMonth = open.locale("en").format("MMMM");
                        let closeDate = close.format("D");
                        let closeMonth = close.locale("en").format("MMMM");

                        that.$store.commit(that.namespace + '/setOpen', open.format("YYYY-MM-DD"));
                        that.$store.commit(that.namespace + '/setClose', close.format("YYYY-MM-DD"));

                        that.$store.commit(that.namespace + '/setOpenMonth', openMonth);
                        that.$store.commit(that.namespace + '/setOpenDate', openDate);
                        that.$store.commit(that.namespace + '/setCloseMonth', closeMonth);
                        that.$store.commit(that.namespace + '/setCloseDate', closeDate);

                        let generalForm = Object.assign({}, that.$store.state[that.namespace]);
                        // console.log("map=", map);

                        let xIndex = map.open.indexOf(generalForm.open);
                        let yIndex = map.close.indexOf(generalForm.close);
                        console.log("xIndex=", xIndex + " yIndex=", yIndex);

                        let newResult = {
                            xref: 'x1',
                            yref: 'y1',
                            x: generalForm.open,
                            y: generalForm.close,
                            text: '<b>open</b>: ' + open.locale(language).format("MMM D, YYYY") + '<br><b>close</b>: ' + close.locale(language).format("MMM D, YYYY") +
                                    '<br><b>' + parameterToMapText + '</b>: ' + point.target,
                            showarrow: true,
                            font: {
                                color: textColor,
                                size: 10
                            },
                            bgcolor: 'white',
                            bordercolor: 'black',
                            borderwidth: 1,
                            borderpad: 3,
                            align: 'left'

                        };
                        let annotations = [];
                        annotations.push(newResult);

                        Plotly.relayout(that.divName, {
                            annotations: annotations});

                        $("#chartdivfixed").css("visibility", "visible");
                        //  that.getTrades(generalForm);
                    }
                }

                console.log("runPlotly() done.");
            }
        }
    }
</script>