<template>
    <div v-bind:style="{width: width}">
        <tabs-box></tabs-box>
    </div>
</template>

<script>

    import tabsBox from '../../components/tabs-box';

    export default {
        name: "dashboard",
        components: {
            tabsBox
        },
        mounted() {
           // console.log("Dashboard starting.");
        },
        computed: {
            program() {
               // console.log("program() starting.");
                let activeModuleIndex = this.$store.state.activeModuleIndex;
               // console.log("activeModuleIndex=", activeModuleIndex);
                let program = null;
                let moduleArray = this.$store.getters['moduleArray'];
               // console.log("moduleArray=", moduleArray);
                if (typeof moduleArray[activeModuleIndex] !== 'undefined') {
                    program = moduleArray[activeModuleIndex].program;
                }
               // console.log("program=", program);
                return program;
            },
            width() {
                let width = "auto";
                if (["Calculator", "Search", "StartPage", "TradeMaps"].includes(this.program)) {
                    width = "fit-content";
                }
              //  console.log("width=", width);
                return width;
            }
        },
        methods: {
            /**
             * Set current active on click
             * @param event
             */
            setActiveTab(event) {
                // get all tab links
                const tab = event.target.closest('[role="tablist"]');
                const links = tab.querySelectorAll(".nav-link");
                // remove active tab links
                for (let i = 0; i < links.length; i++) {
                    links[i].classList.remove("active");
                }

                // set current active tab
                event.target.classList.add("active");

                // set clicked tab index to bootstrap tab
                return parseInt(event.target.getAttribute("data-tab"));
            }
        }
    };
</script>
