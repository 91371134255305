<template>
    <div v-if="$store.state[namespace].legs > 1"
         style="text-align: center; background-color: white; border: 1px solid gray; padding: 1px 2px 1px 1px">
        <div style="font-weight: bold; color: black;">
            {{title}}
        </div>
        <table style="text-align: center; background-color: white; margin: 0px; width: 100%;">
            <tbody>
                <tr><td v-for="(value, index) in displayOptions[0]" v-bind:key="index" style="padding-right: 0px">
            <b-button type="button" style="text-align: center;  color: white; cursor: pointer; width: 100%; height: 20px; padding: 1px;"                                                 
                      v-bind:style="{backgroundColor: rowColorArray[index]}"
                      v-bind:id="index"
                      v-if="hideButtonArray[index]"
                      v-on:click="handleButtonClick">{{String.fromCharCode(9650)}}
            </b-button>
            </td></tr>
            <template>
                <tr style="font-style: verdana;"
                    v-for="(option, outerIndex) in displayOptions" v-bind:key="outerIndex">
                    <td  v-for="(value, index) in option"  v-bind:key="index" style=" padding: 0 1px 0 1px; white-space: nowrap"
                         v-bind:style="{color: rowColorArray[index]}">{{value}}
                    </td>
                </tr>
            </template>
            <tr>
                <td  v-for="(value, index) in displayOptions[0]"
                     v-bind:key="index"
                     v-bind:style="{color: rowColorArray[index]}">{{String.fromCharCode(8942)}}
                </td>
            </tr>
            </tbody>
        </table> 

    </div>
</template>

<script>
    import {spreadTitleLegs, ContractSeparationCounter} from "../js/main";

    export default {
        mounted: function () {
            console.log("contract-aligner.vue mounted() starting. this.title=", this.title);
        },
        props: ['namespace', 'title'],
        computed: {
            displayOptions() {
                let optionsSubset = this.$store.state[this.namespace].browserSideOnly.alignerOptions.slice(0, this.$store.state[this.namespace].browserSideOnly.numberOfIncompleteSpreads + 2);

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                let invertDatesMultiplier = 1;
                let noLegSwapping = true;

                let displayOptions = optionsSubset.map(rowArray => {
                    generalForm.selected = [rowArray.join('/')];

                    let tickerLegs = spreadTitleLegs(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping);
                    let leadingCharacter = tickerLegs[0].charAt(0);

                    if (tickerLegs[0].indexOf("--") === -1 && leadingCharacter === "+") {
                        tickerLegs[0] = tickerLegs[0].substring(1);
                    }
                    return tickerLegs;
                });

                return displayOptions;
            },
            rowColorArray() {
                return this.$store.state.siteData.legColors;
            },
            hideButtonArray() {
                let contractSeparation = ContractSeparationCounter(this.$store.state[this.namespace].sampleContract[0], this.$store.state[this.namespace].sampleContract[1]);
                console.log("Math.sign(contractSeparation)=", Math.sign(contractSeparation));
                let arr =  [true, true, true, true];
                if (Math.sign(contractSeparation) > 0 && this.$store.state[this.namespace].program === "TradeMaps") {
                    arr = [false, true, true, true];
                } else if (Math.sign(contractSeparation) < 0 && this.$store.state[this.namespace].program === "TradeMaps") {
                    arr = [true, false, true, true];
                } 
                console.log("arr=", arr);
                return arr;
            }
        },
        methods: {
            handleButtonClick(e) {
                console.log("handleButtonClick() starting.");
                this.$store.dispatch([this.namespace] + '/shiftSampleContract', {index: e.target.id});
            }
        }
    }
</script>

<style>
    .contractAligner{
        margin: 0px 0px 3px 0px;
    }
</style>