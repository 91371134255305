<template>
    <div style="width: fit-content; background-color: white;" id="inputDiv">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <p style="padding: 4px">
                    Metti qui le istruzioni.
                </p>
            </b-sidebar>
        </div>
        <h2 style="text-align: left;">Open Spreads
        </h2>
        <table v-if="namespace !== 'undefined'" style="text-align: left; margin-bottom: 2px;"> 
            <tr>
                <td style="vertical-align: top;">
                    <table>
                        <tr>
                            <td style="vertical-align: top">  
                        <commodity v-bind:namespace="namespace" index="0"></commodity>
                </td>
                <td style="padding-left: 20px; padding-right: 20px">
            <number-of-contracts-apart v-bind:namespace="namespace"></number-of-contracts-apart> 
            </td>
            </tr>
        </table>
        </td>
        <td style="vertical-align: top; text-align: right; height: available; padding: 1px; background-color: white; vertical-align: top">

            <table style="background-color: white; height: 100%; background-color: white; height: 100%;">
                <tr>
                    <td style="vertical-align: bottom; background-color: white">
                <b-row align-h="start" align-v="end" class="row no-gutters" style=" background-color: white; padding-bottom: 0px">
                    <b-col md="auto">  
                        <b-button-group style="border: 1px solid gray; padding: 1px; white-space: nowrap; background-color: #E8E8E8"> 
                            <balloon-toggle v-bind:namespace="namespace" style="margin: 1px"></balloon-toggle>
                            <b-button v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray; margin: 1px;">Help</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
        </td>
        </tr>
        </table>

        </td>
        </tr>
        </table>                   
    </div>
</template>

<script>

    import commodity from '@/components/commodity';
    import numberOfContractsApart from '@/components/number-of-contracts-apart';
    import balloonToggle from '@/components/balloon-toggle';

    export default {
        components: {
            commodity,
            numberOfContractsApart,
            balloonToggle
        },
        mounted() {
            console.log("open-spreads-input.vue mounted() starting. this.namespace=", this.namespace);
       
            const observer = new ResizeObserver(entries => {
               // console.log("ResizeObserver() starting.");
                for (let entry of entries) {
                   // console.log("entry=", entry);
                    // Now do something with the resized element
                   console.log("entry.contentRect.height=", entry.contentRect.height);
                   this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height + 7);
                }
            });
            observer.observe(document.querySelector('#' + this.namespace + 'InputDiv')); 
        },
        props: ['namespace']
    };

</script>
