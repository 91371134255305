<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <p style="padding: 4px">
                    Metti qui le istruzioni.
                </p>
            </b-sidebar>
        </div>
        <h2 style="text-align: left;">Calculator
        </h2>
        <table v-if="namespace !== 'undefined'" style="text-align: left; margin-bottom: 0px;"> 
            <tr>
                <td style="vertical-align: top;">
                    <table>
                        <tr>
                            <td style="vertical-align: top">  
                        <commodity-box v-bind:namespace="namespace">
                            <template v-slot:contract-aligner>
                                <contract-aligner v-bind:namespace="namespace" v-bind:title="'Aligner'"></contract-aligner>     
                            </template>
                            <template v-slot:y1>
                                <y1 v-bind:namespace="namespace"></y1>
                            </template>                   
                        </commodity-box>
                </td>
            </tr>
        </table>
        </td>
        <td style="vertical-align: top; text-align: right; height: available; padding: 1px; background-color: white;">

            <table style="background-color: white; height: 100%; background-color: white;">
                <tr>
                    <td style="vertical-align: top">
                <open-close-selector v-bind:namespace="namespace"></open-close-selector>
        </td>

        <td style="vertical-align: top; background-color: white">
        <b-row align-h="start" align-v="start" class="row no-gutters" style=" background-color: white; padding-bottom: 0px">
            <b-col md="auto">  
                <b-button-group style="border: 1px solid gray; padding: 1px; white-space: nowrap; background-color: #E8E8E8"> 
                    <b-button v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray; margin: 1px;">Help</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        </td>
        </tr>
        </table>

        </td>
        </tr>
        </table>                   
    </div>
</template>

<script>

    import commodityBox from '@/components/commodity-box';
    import contractAligner from '@/components/contract-aligner';
    import openCloseSelector from '@/components/open-close-selector';
    import y1 from '@/components/y1.vue';

    export default {
        components: {
            commodityBox,
            contractAligner,
            openCloseSelector,
            y1
        },
        mounted() {
            console.log("calculator-input.vue mounted() starting. this.namespace=", this.namespace);
        },
        props: ['namespace']
    };

</script>
